import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import { useForm, FormProvider } from "react-hook-form";

import { UploadBox, PreviewBox, InputFields } from "../../Components";
import leads, {
  setLead,
  getLead,
  updateLead,
  removeDocsFromLead,
  getLeadLogs,
  updateMedia,
} from "../../store/leads";
import { updateSnackbar } from "../../store/snackbar";

const StyledDiv = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: 100,
  "& .details": {
    padding: 10,
  },
  "& .title": {
    backgroundColor: theme.palette.grey[200],
    marginTop: 10,
  },
  "& .detail-box": {
    padding: 5,
    "& small": {
      color: theme.palette.grey[700],
    },
    "& span": {
      color: theme.palette.primary.main,
    },
  },
  "& .details-right": {
    textAlign: "right",
  },
  "& .details-middle": {
    textAlign: "center",
  },
}));

const StyledMarkup = styled("div")(({ theme }) => ({
  padding: 10,
  borderLeft: `1px solid ${theme.palette.grey[200]}`,
  position: "fixed",
  width: "28%",
  "& a": {
    color: theme.palette.text.main,
  },
  "& .message": {
    padding: "5px 10px",
    marginTop: 10,
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  "& .time": {
    fontSize: 12,
    color: theme.palette.text.primary,
  },
  "& img": {
    width: "100%",
  },
}));

const BasicDetails = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = React.useState(false);
  const { lead, errorList } = useSelector((state) => state.leads);

  const methods = useForm({
    defaultValues: lead,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data) => {
    await dispatch(updateLead(data));
    !errorList.length
      ? dispatch(
          updateSnackbar({
            status: true,
            message: ["Lead updated successfully"],
            type: "success",
          })
        )
      : dispatch(
          updateSnackbar({
            status: true,
            message: errorList,
            type: "error",
          })
        );
    dispatch(getLeadLogs(lead._id));
    setEdit(false);
  };

  React.useEffect(() => {
    reset(lead);
  }, [lead]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12} className="title">
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{ flexGrow: 1 }}
            >
              Basic Details
              {edit ? (
                <>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    startIcon={<SaveIcon color="success" />}
                    style={{ float: "right", marginRight: 5 }}
                    variant="outlined"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => setEdit(false)}
                    startIcon={<ClearIcon color="error" />}
                    style={{ float: "right", marginRight: 5 }}
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => setEdit(true)}
                  startIcon={<EditIcon color="secondary" />}
                  style={{ float: "right", marginRight: 5 }}
                  variant="outlined"
                  color="primary"
                >
                  Edit
                </Button>
              )}
            </Typography>
          </Grid>
          {edit ? (
            <>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="fullName"
                  label="Full Name"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box details-middle">
                <InputFields
                  name="phone"
                  label="Phone"
                  type="number"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box details-right">
                <InputFields
                  name="email"
                  label="Email"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="targetCountry"
                  label="Target Country"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="collegeName"
                  label="College Name"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box details-middle">
                <InputFields
                  name="courseName"
                  label="Course Name"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box details-right">
                <InputFields
                  name="courseFee"
                  label="Course Fee"
                  type="number"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="loanAmount"
                  label="Loan Amount"
                  type="number"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box details-middle">
                <InputFields
                  name="bankName"
                  label="Bank Name"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} className="detail-box">
                <InputFields
                  name="comments"
                  label="Comments"
                  type="text"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <small>Lead Source</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead?.source?.toUpperCase() || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box details-middle">
                <small>Slot Date</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead.cbSchedule
                    ? moment(lead.cbSchedule).format("ll")
                    : "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box details-right">
                <small>Slot Time</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead.timeSlotFrom && lead.timeSlotTo
                    ? `${moment(lead.timeSlotFrom).format("LT")} - ${moment(
                        lead.timeSlotTo
                      ).format("LT")}`
                    : "NA"}
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={4} className="detail-box">
                <small>Full Name</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead?.fullName || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box details-middle">
                <small>Phone Number</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead?.phone || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box details-right">
                <small>Email</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead?.email || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box">
                <small>Target Country</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead?.targetCountry || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box details-middle">
                <small>College Name</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead?.collegeName || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box details-right">
                <small>Course Name</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead?.courseName || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box">
                <small>Course Fee</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead?.courseFee || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box details-middle">
                <small>Loan Amount</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  Rs. {lead?.loanAmount}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box details-right">
                <small>Bank Name</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead?.bankName || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12} className="detail-box">
                <small>Comments</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead?.comments || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <small>Lead Source</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead?.source?.toUpperCase() || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box details-middle">
                <small>Slot Date</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead.cbSchedule
                    ? moment(lead.cbSchedule).format("ll")
                    : "NA"}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box details-right">
                <small>Slot Time</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {lead.timeSlotFrom && lead.timeSlotTo
                    ? `${moment(lead.timeSlotFrom).format("LT")} - ${moment(
                        lead.timeSlotTo
                      ).format("LT")}`
                    : "NA"}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};

const DocumentDetails = ({ item, index, key }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { lead, errorList } = useSelector((state) => state.leads);
  const [edit, setEdit] = React.useState(false);

  const methods = useForm({
    defaultValues: item,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data) => {
    await dispatch(updateMedia(data));
    !errorList.length
      ? dispatch(
          updateSnackbar({
            status: true,
            message: ["Document updated successfully"],
            type: "success",
          })
        )
      : dispatch(
          updateSnackbar({
            status: true,
            message: errorList,
            type: "error",
          })
        );

    dispatch(getLeadLogs(lead._id));
    setEdit(false);
  };

  React.useEffect(() => {
    reset(lead);
  }, [lead]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} key={key}>
          <Grid
            item
            xs={6}
            style={{
              borderBottom: `1px solid ${theme.palette.grey[400]}`,
              paddingBottom: 10,
            }}
          >
            <PreviewBox
              file={item}
              actionButtons
              deleteCallBack={(_id) => dispatch(removeDocsFromLead(_id))}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className="detail-box"
            style={{
              borderBottom: `1px solid ${theme.palette.grey[400]}`,
              padding: 10,
            }}
          >
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  paddingBottom: 5,
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                }}
              >
                {edit ? (
                  <>
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      startIcon={<SaveIcon color="success" />}
                      style={{ float: "right", marginRight: 5 }}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => setEdit(false)}
                      startIcon={<ClearIcon color="error" />}
                      style={{ float: "right", marginRight: 5 }}
                      variant="outlined"
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => setEdit(true)}
                    startIcon={<EditIcon color="secondary" />}
                    style={{ float: "right", marginRight: 5 }}
                    variant="outlined"
                    color="primary"
                  >
                    Edit
                  </Button>
                )}
              </Grid>
              {edit ? (
                <>
                  <Grid item xs={6}>
                    <InputFields
                      name={`type`}
                      label="Doument Type"
                      type="select"
                      size="small"
                      options={[
                        {
                          value: "aadhar-full",
                          label: "Aadhar Full",
                        },
                        {
                          value: "aadhar-front",
                          label: "Aadhar Front",
                        },
                        {
                          value: "aadhar-back",
                          label: "Aadhar Back",
                        },
                        {
                          value: "pan",
                          label: "Pan",
                        },
                        {
                          value: "passport",
                          label: "Passport",
                        },
                        {
                          value: "driving-license",
                          label: "Driving License",
                        },
                        {
                          value: "voter-id",
                          label: "Voter ID",
                        },
                        {
                          value: "bank-statement",
                          label: "Bank Statement",
                        },
                        {
                          value: "other",
                          label: "Other",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputFields
                      name={`verified`}
                      label="Document Verified?"
                      type="switch"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputFields
                      name={`comments`}
                      label="Comments"
                      placeholder="eg. Document is not clear"
                      type="text"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    <small>Doument Type</small>
                    <br />
                    <Typography variant="body1" gutterBottom>
                      {item?.type?.toUpperCase() || "NA"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <small>Doument Status</small>
                    <br />
                    <Typography variant="body1" gutterBottom>
                      {item?.verified ? "Verified" : "Not Verified"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <small>Comments</small>
                    <br />
                    <Typography variant="body1" gutterBottom>
                      {item?.comments || "NA"}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

const ViewLead = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { _id } = useParams();
  const { lead, leads, loading, logs } = useSelector((state) => state.leads);

  React.useEffect(() => {
    const leadData = leads.find((lead) => lead._id === _id);

    if (leadData?._id) dispatch(setLead(leadData));
    else dispatch(getLead(_id));
  }, [leads?.length]);

  React.useEffect(() => {
    dispatch(getLeadLogs(_id));
  }, []);

  return (
    <StyledDiv>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="h5" gutterBottom>
                  LEAD-{lead?.id} [{lead?.leadStatus?.toUpperCase()}]
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  Created By {lead?.createdBy?.fullName || "WEBSITE"} on{" "}
                  {moment(lead?.createdAt).format("lll")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <BasicDetails />
              </Grid>
              <Grid item xs={12} className="title">
                <Typography variant="h6" gutterBottom>
                  Documents
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <UploadBox
                  boxText="Click or Drag and drop here to Upload"
                  reftype="lead"
                  refId={lead._id}
                  uploadCallBack={async (uploadedFiles) => {
                    uploadedFiles.length &&
                      (await dispatch(
                        updateLead({
                          ...lead,
                          docUpload: true,
                          documents: [
                            ...uploadedFiles.map((item) => item._id),
                            ...lead?.documents.map((item) => item._id),
                          ],
                        })
                      ));
                    dispatch(getLeadLogs(_id));
                  }}
                  accept={{
                    "image/*": [".jpeg", ".jpg", ".png"],
                    "application/pdf": [".pdf"],
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {lead?.documents?.map((item, index) => (
                  <DocumentDetails
                    item={item}
                    index={index}
                    key={`${item._id}-${index}`}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <StyledMarkup>
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  background: theme.palette.grey[400],
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                Lead Activity
              </Typography>
              <Grid
                container
                spacing={2}
                style={{
                  padding: 10,
                  overflowY: "scroll",
                  height: "80vh",
                  marginTop: 10,
                }}
              >
                {logs?.map((item) => (
                  <Grid item xs={12} className="message">
                    <ReactMarkdown
                      children={item.message}
                      remarkPlugins={[remarkGfm]}
                    />
                    <span className="time">
                      {item.createdAt
                        ? moment(item.createdAt).format("LLLL")
                        : ""}
                    </span>
                  </Grid>
                ))}
              </Grid>
            </StyledMarkup>
          </Grid>
        </Grid>
      )}
    </StyledDiv>
  );
};

export default ViewLead;
