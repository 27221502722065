import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';

const StyledDiv = styled('div')(({ theme }) => ({
  "& .nav-button": {
    color: "#fff",
    border: "none !important",
    padding: 0,
    "& span": {
      fontSize: "13px",
      display: "block"
    }
  },
  "& .nav-button-prev": {
    backgroundColor: theme.palette.primary.main,
    padding: "0 15px 0 0",
  },
  "& .nav-button-next": {
    backgroundColor: theme.palette.primary.main,
    padding: "0 0 0 30px",
  },
  "& .nav-icon": {
    height: "inherit",
    "& svg": {
      marginTop: "15px"
    }
  },
  "& .nav-icon-prev": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "5px 0 0 5px",
    padding: "5px 20px"
  },
  "& .nav-icon-next": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0 5px 5px 0",
    padding: "5px 20px"
  }
}));

const NavButtonGroup = (props) => {
  const { addition, additionL, callBack, psd, buttons, nextText, prevText, nextDisabled, prevDisabled } = props;
  const handleNext = () => {
    callBack && callBack();
  }

  const handlePrevious = () => {
    document.getElementById(psd).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }
  return (
    <StyledDiv>
      <Stack direction="row" spacing={2}>
        {
          addition ?? addition
        }
        {
          buttons?.[0] ? (
            <Button
              variant="outlined"
              startIcon={<div className='nav-icon nav-icon-prev'><ArrowBackIcon /></div>}
              className='nav-button nav-button-prev'
              size="large"
              onClick={handlePrevious}
              disabled={prevDisabled}
            >
              <span>{prevText || 'PREVIOUS'}</span>
            </Button>
          ) : ""
        }
        {
          buttons?.[1] ? (
            <Button
              variant="contained"
              endIcon={<div className='nav-icon nav-icon-next'><ArrowForwardIcon /></div>}
              className='nav-button nav-button-next'
              size="large"
              onClick={handleNext}
              disabled={nextDisabled}
              type={callBack ? 'button' : 'input'}
            >
              <span>{nextText || 'NEXT'}</span>
            </Button>
          ) : ""
        }
        {
          additionL ?? additionL
        }
      </Stack>
    </StyledDiv>
  )
}

export default NavButtonGroup;