import { useState } from "react";
import axios from "axios";
import { request } from "../utils/API";

const useUpload = () => {
  const [currentStatus, setCurrentStatus] = useState("");
  const [files, setFiles] = useState([]);

  let backendHost = process.env.REACT_APP_REMOTE_URL_LOCAL;

  if (process.env.REACT_APP_MODE === "production")
    backendHost = process.env.REACT_APP_REMOTE_URL_PRODUCTION;
  else if (process.env.REACT_APP_MODE === "staging")
    backendHost = process.env.REACT_APP_REMOTE_URL_STAGING;

  const url = `${backendHost}/media`;

  const uploadFile = async (acceptedFiles, reftype, refId, type) => {
    setCurrentStatus("uploading");
    const tList = acceptedFiles?.map((item) => ({
      name: item.name,
      uploadStatus: "uploading",
    }));

    setFiles(tList);

    for (var i = 0; i < acceptedFiles.length; i++) {
      const formData = new FormData();
      formData.append("ref", reftype);
      formData.append("refId", refId);
      type && formData.append("type", type);
      formData.append("file", acceptedFiles[i]);
      formData.append("name", acceptedFiles[i]?.name);

      await axios
        .post(url, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((response) => {
          tList[i] = { ...response.data, uploadStatus: "uploaded" };
          setFiles(tList);
        })
        .catch((error) => {
          tList[i] = { ...tList[i], uploadStatus: "failed" };
          setFiles(tList);
        });
    }

    setCurrentStatus("completed");
  };

  const deleteFile = (_id) => {
    return request({
      url: `/media/${_id}`,
      method: "put",
      body: {
        status: 0,
      },
    })
      .then(
        (response) => {
          const nData = files ? files.filter((item) => _id !== item._id) : [];
          setFiles(nData);
        },
        (err) => console.log(err)
      )
      .catch((error) => console.log(error));
  };

  return { currentStatus, files, uploadFile, deleteFile };
};

export default useUpload;
