import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  InputFields,
  NavButtonGroup,
  Backdrop,
  Editor,
  UploadBox,
  PreviewBox,
} from '../../Components';

import { updateSnackbar } from '../../store/snackbar';
import { createBlog } from '../../store/blogs';
import { createTag, getTags } from '../../store/tags';

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
}));

const CreateBlog = () => {
  const dispatch = useDispatch();
  const { loading, errorList } = useSelector(state => state.blogs);
  const tags = useSelector(state => state.tags.tags);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      title: "",
      metaDescription: "",
      description: "",
      breadCrumb: "",
      slug: "",
      content: "",
      tags: [],
      views: 0,
      banner: {
        location: ''
      }
    }
  });

  const { handleSubmit, watch, setValue } = methods;

  const onSubmit = async data => {
    await dispatch(createBlog(data));
    navigate(`/blogs`);
  }

  React.useEffect(() => {
    loading ? setOpen(true) : setOpen(false);
  }, [loading]);

  React.useEffect(() => {
    if (errorList?.length) {
      dispatch(updateSnackbar({
        status: true,
        message: errorList,
        type: 'error'
      }));
    }
  }, [errorList]);

  React.useEffect(() => {
    dispatch(getTags())
  }, []);

  React.useEffect(() => {
    setValue('slug', watch('title')?.toLowerCase()?.split(' ')?.join('-'));
  }, [watch('title')]);

  return (
    <StyledDiv>
      <Backdrop open={open} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Create a new Blog
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <InputFields
                name="title"
                label="Blog Title"
                type="text"
                size="small"
                placeholder="eg. Education Loan in India"
              />
            </Grid>
            <Grid item xs={6}>
              <InputFields
                name="slug"
                label="Blog Slug"
                type="text"
                size="small"
                placeholder="education-loan-in-india"
              />
            </Grid>
            <Grid item xs={6}>
              <InputFields
                name="breadCrumb"
                label="Bread Crumb Text"
                type="text"
                size="small"
                placeholder="Education Loan in India"
              />
            </Grid>
            <Grid item xs={6}>
              <InputFields
                name="tags"
                label="Tags"
                type="autocomplete-multiple"
                size="small"
                options={tags}
                vals={watch('tags')}
                placeholder="education-loan-in-india"
                optionlabel='name'
                callBack={(data, reason) => {
                  console.log(data, reason);
                  if (reason === 'selectOptionClick') {
                    setValue('tags', [...watch('tags'), data]);
                  } else if (reason === 'removeOptionClick') {
                    setValue('tags', watch('tags').filter(tag => tag._id !== data._id));
                  } else if (reason === 'clear') {
                    setValue('tags', []);
                  }
                }}
                addNewOptionFly
                addNewOptionFlyCB={async name => {
                  const tagData = await dispatch(createTag({ name }));
                  setValue('tags', [...watch('tags'), tagData?.payload]);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFields
                name="description"
                label="Blog Description"
                type="text"
                size="small"
                multiline
                rows={4}
                placeholder="eg. Education Loan in India"
              />
            </Grid>
            <Grid item xs={6}>
              <InputFields
                name="metaTitle"
                label="Blog Meta Title"
                type="text"
                size="small"
                placeholder="eg. Education Loan in India"
              />
            </Grid>
            <Grid item xs={6}>
              <InputFields
                name="metaDescription"
                label="Blog Meta Description"
                type="text"
                size="small"
                multiline
                rows={4}
                placeholder="eg. Education Loan in India"
              />
            </Grid>
            <Grid item xs={12}>
              <UploadBox
                boxText='Upload Blog banner image'
                reftype='blog'
                refId={null}
                multiple={false}
                uploadCallBack={async uploadedFiles => {
                  setValue('banner', uploadedFiles[0]);
                }}
                accept={{
                  'image/*': ['.jpeg', '.jpg', '.png']
                }}
              />
            </Grid>
            {
              watch('banner')?.location && (
                <Grid item xs={12}>
                  <PreviewBox
                    file={watch('banner')}
                  />
                </Grid>
              )
            }
            <Grid item xs={12}><h2>Blog Content</h2></Grid>
            <Grid item xs={12}>
              <Editor
                html={''}
                name="content"
              />
            </Grid>
            <Grid item xs={12}>

              <Stack spacing={2} direction="row">
                <NavButtonGroup
                  buttons={[0, 1]}
                  nextText="Create Blog as Draft"
                  fullWidth
                  callBack={() => onSubmit({ ...watch(), status: 1 })}
                />
                <NavButtonGroup
                  buttons={[0, 1]}
                  nextText="Create Blog and Publish"
                  fullWidth
                  callBack={() => onSubmit({ ...watch(), status: 2 })}
                />
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </StyledDiv>
  )
}

export default CreateBlog;