import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "../utils/API";

const initialState = {
  count: 0,
  course: {},
  courses: [],
  errorList: [],
  loading: false,
};

export const getCourses = createAsyncThunk(
  "courses/get",
  async (args = null, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/courses",
        method: "get",
      });

      return response;
    } catch (err) {
      return rejectWithValue(
        err.details || ["Unable fetch courses data. Please try again later!"]
      );
    }
  }
);

export const getCollegeCourses = createAsyncThunk(
  "collegeCourses/get",
  async (args = null, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/courses/college/${args}`,
        method: "get",
      });

      return response;
    } catch (err) {
      return rejectWithValue(
        err.details || ["Unable fetch courses data. Please try again later!"]
      );
    }
  }
);

export const getCourse = createAsyncThunk(
  "course/get",
  async (args = null, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/courses/${args}`,
        method: "get",
      });

      return response;
    } catch (err) {
      return rejectWithValue(
        err.details || ["Unable fetch courses data. Please try again later!"]
      );
    }
  }
);

export const createCourse = createAsyncThunk(
  "course/create",
  async (args = null, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/courses`,
        method: "post",
        body: args,
      });

      return response;
    } catch (err) {
      return rejectWithValue(
        err.details || ["Unable fetch courses data. Please try again later!"]
      );
    }
  }
);

export const updateCourse = createAsyncThunk(
  "course/update",
  async (args = null, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/courses/${args._id}`,
        method: "put",
        body: args,
      });

      return response;
    } catch (err) {
      return rejectWithValue(
        err.details || ["Unable fetch courses data. Please try again later!"]
      );
    }
  }
);

export const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setCourse: (state, action) => {
      state.course = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Create Course
    builder.addCase(createCourse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCourse.fulfilled, (state, action) => {
      state.courses = [action.payload, ...state.courses];
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(createCourse.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Get list of courses
    builder.addCase(getCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCourses.fulfilled, (state, action) => {
      state.courses = action.payload.data;
      state.count = action.payload.count;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getCourses.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Get single course
    builder.addCase(getCourse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCourse.fulfilled, (state, action) => {
      state.course = action.payload;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getCourse.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Get college courses
    builder.addCase(getCollegeCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollegeCourses.fulfilled, (state, action) => {
      state.courses = action.payload;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getCollegeCourses.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Update single course
    builder.addCase(updateCourse.fulfilled, (state, action) => {
      state.course = action.payload;
      state.errorList = [];
    });
    builder.addCase(updateCourse.rejected, (state, action) => {
      state.errorList = action.payload;
    });
  },
});

export const { setCourse } = coursesSlice.actions;

export default coursesSlice.reducer;
