import React from 'react';
import Icon from '@mui/material/Icon';

export default function (props) {
  const { width, height, svgLink } = props;

  return (
    <Icon style={{ textAlign: "center" }}>
      <img style={{
        width: width || 25,
        height: height || "auto"
      }}
        src={svgLink}
      />
    </Icon>
  )
}