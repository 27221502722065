import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  type: "error",
  message: [],
  status: false
}

export const snackBarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    updateSnackbar: (state, action) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.status = action.payload.status;
    }
  },
})

export const { updateSnackbar } = snackBarSlice.actions

export default snackBarSlice.reducer