import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import useUpload from "../../Hooks/useUpload";

const StyledDiv = styled('div')(({ theme }) => ({
  marginBottom: 0,
  width: '100%',
  "& .preview-box": {
    marginTop: 20,
    borderRadius: 5
  },
  "& .action-buttons": {
    marginTop: 20,
  },
}));

const Preview = ({ file, deleteCallBack, actionButtons }) => {
  const { deleteFile } = useUpload();
  const [open, setOpen] = React.useState(false);
  const [currentFile, setCurrentFile] = React.useState('');

  return (
    <StyledDiv>
      <Card sx={{ width: '100%', marginTop: 1 }} elevation={5}>
        <CardActionArea
          onClick={() => setOpen(true)}
        >
          <CardMedia
            component="img"
            height={250}
            image={`${process.env.REACT_APP_AWS_LINK}${file?.key}thumb.jpg`}
            alt={file?._id}
          />
        </CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="b" component="div">
            {(file?.name || file?.type)?.toUpperCase()}
          </Typography>
        </CardContent>
        {
          actionButtons ? (
            <CardContent style={{ marginTop: -15, marginBottom: -15 }}>
              <Stack direction="row-reverse" spacing={2}>
                <Tooltip title="Remove File">
                  <IconButton color="error" onClick={e => {
                    e.stopPropagation();
                    deleteFile(file?._id);
                    deleteCallBack && deleteCallBack(file?._id);
                  }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download File">
                  <a href={`${file?.location}`} onClick={e => e.stopPropagation()} target="_blank" >
                    <IconButton color="primary">
                      <FileDownloadIcon />
                    </IconButton>
                  </a>
                </Tooltip>
              </Stack>
            </CardContent>
          ) : ""
        }
      </Card>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {
            file?.mimeType === 'pdf' ? (
              <object data={file?.location} type="application/pdf" width="100%" height="1000">
              </object>
            ) : (
              <div style={{
                backgroundImage: `url(${file?.location})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                position: 'relative',
                width: '100%',
                height: 700,
              }}
              >
              </div>
            )
          }
        </DialogContent>
      </Dialog>
    </StyledDiv>
  );
}

export default Preview;