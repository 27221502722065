import React from 'react';
import { useFormContext, Controller, get } from "react-hook-form";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import PlaceIcon from '@mui/icons-material/Place';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel'
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Chip from '@mui/material/Chip';
import _, { values } from 'lodash';

import { request } from '../../utils/API';
import { CustomIcon } from '..';

const StyledDiv = styled('div')(({ theme }) => ({
  backgroundColor: "#36373a",
  padding: "8px 0 10px 15px",
  borderRadius: "5px",
  "& .sub-text": {
    marginTop: "-10px",
    fontSize: "10px",
    marginLeft: "33px",
    color: "#bdb0b0"
  }
}));

export default function InputFields(props) {
  const { type, options, name, id, label, url, callBack, vName, pName, index, xs, onClearCB, minLength, maxLength, selectOnSinglular, blurCallBack, errorCallBack, triggerValidation, defaultValue, startadornment, mvName, uniqueFilterKey, list, endadornment, checkToVal, compType, extensionComp, upperCase, addNewOption, addNewCallBack, altName, placeholder, prefix, tCB, oIndex, addNewOptionFly, addNewOptionFlyCB, vals, optionlabel } = props;
  const [remoteOptions, setRemoteOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { setValue, control, getValues, trigger } = useFormContext();
  const [valueState, setValueState] = React.useState('');

  const searchValue = value => {
    if (value.length >= (minLength || 1) && value.length <= (maxLength || 10)) {
      setLoading(true);
      request({
        url: `${url}${value}`,
        method: 'get'
      }).then(
        response => {
          if (response.error) errorCallBack && errorCallBack(value, index);
          else setRemoteOptions(response);
          setLoading(false);
          tCB && tCB(response);
        },
        err => console.log(err)
      ).catch(error => console.log({ status: 500 }))
    } else {
      setLoading(false);
    }
  }

  const debouncedSearch = _.debounce(searchValue, 200);

  const onChange = (e) => {
    debouncedSearch(e.target.value);
  }

  const handleBlur = (e) => blurCallBack && blurCallBack(e.target.value, index);

  const optionSelected = option => {
    pName && setValue(pName, option);
    trigger(name);
    callBack && list ? callBack(option, index, remoteOptions) : callBack(option, index);
    addNewCallBack && option?._id === 'add-new' && addNewCallBack(index);
  }

  const handleChange = (newValue) => {
    setValue(name, newValue, { shouldTouch: true });
    altName && setValue(altName, newValue, { shouldTouch: true });
    triggerValidation && trigger(name);
  };

  const onClear = (event, reason) => {
    if (reason === 'clear') {
      onClearCB(index)
    }
    trigger(name);
  }

  return (
    <>
      {
        (type === 'text' || type === 'number' || type === 'phonenumber' || type === 'password' || type === 'time') && (
          <FormControl fullWidth >
            <Controller
              name={name}
              control={control}
              render={({ field, formState }) => <TextField
                {...field}
                {...props}
                InputProps={{
                  startAdornment: startadornment ? <InputAdornment position="start">{startadornment}</InputAdornment> : "",
                  endAdornment: endadornment ? <InputAdornment position="start">{endadornment}</InputAdornment> : "",
                }}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                error={!!get(formState.errors, name)}
                value={field.value}
              />}
            />
          </FormControl>
        )
      }
      {
        (type === 'email') && (
          <FormControl fullWidth >
            <Controller
              name={name}
              control={control}
              render={({ field, formState }) => <TextField
                {...field}
                label={props.label}
                size={props.size}
                type="text"
                onChange={event => {
                  callBack && callBack(event.target.value);
                }}
                onBlur={event => {
                  callBack && callBack(event.target.value);
                }}
                InputProps={{
                  startAdornment: startadornment ? <InputAdornment position="start">{startadornment}</InputAdornment> : "",
                  endAdornment: endadornment ? <InputAdornment position="start">{endadornment}</InputAdornment> : "",
                }}
                error={!!get(formState.errors, name)}
              />}
            />
          </FormControl>
        )
      }
      {
        type === 'select' && (
          <FormControl fullWidth>
            <InputLabel id={id}>{label}</InputLabel>
            <Controller
              id={id}
              name={name}
              control={control}
              render={({ field, formState }) => <Select
                {...field}
                {...props}
                style={{ width: '100%' }}
                error={!!get(formState.errors, name)}
                value={field.value}
              >
                {
                  (addNewOption ? [{ value: 'add-new', label: 'Add New' }, ...(options || [])] : (options || [])).map((item, indx) => (
                    <MenuItem
                      {...props}
                      key={`${indx}-${item.value}`}
                      value={item.value}
                      onClick={e => {
                        pName ? setValue(pName, item) : setValue(name, item.value);
                        list && callBack ? callBack(item, indx, options) : oIndex ? callBack(item, index) : callBack && callBack(item, indx, name);
                        addNewCallBack && item.value === 'add-new' && addNewCallBack(index);
                      }}
                      style={item.value === 'add-new' ? {
                        borderBottom: '1px dotted #fff',
                        background: '#000'
                      } : {}}
                    >
                      {item.label}
                    </MenuItem>
                  ))
                }
              </Select>}
            />
          </FormControl>
        )
      }
      {
        type === 'switch' && (
          <FormControl>
            <Controller
              name={name}
              control={control}
              render={({ field, formState }) => <FormControlLabel control={<Switch {...field} error={!!get(formState.errors, name)} />} label={label} labelPlacement="start" />}
            />
          </FormControl>
        )
      }
      {
        type === 'autocomplete' && (
          <FormControl fullWidth>
            <Controller
              name={name}
              control={control}
              render={({ field, formState }) => <Autocomplete
                {...field}
                name={name}
                onChange={(event, newValue) => {
                  const split = name.split('.');
                  pName ? setValue(pName, newValue || { [split[split.length - 1]]: "" }) : setValue(name, newValue || "");
                  trigger(name);
                  callBack && callBack();
                }}
                disablePortal
                id={name}
                options={options || []}
                renderInput={(params) => <TextField
                  {...params}
                  {...props}
                  name={name}
                  label={label}
                />}
                error={!!get(formState.errors, name)}
              />}
            />
          </FormControl>
        )
      }
      {
        type === 'autocomplete-multiple' && (
          <FormControl fullWidth>
            <Controller
              name={name}
              control={control}
              render={({ field, formState }) => <Autocomplete
                value={vals}
                multiple
                id={name}
                noOptionsText={addNewOptionFly && valueState ? <div style={{ padding: 10, border: '1px solid #ccc', cursor: 'pointer', borderRadius: 5 }} onClick={() => addNewOptionFlyCB && addNewOptionFlyCB(valueState)}><small>+Add</small>&nbsp;&nbsp;<b>"{valueState}"</b></div> : 'No Options'}
                options={options}
                getOptionLabel={(option) => option?.[optionlabel]}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option?.[optionlabel]} {...getTagProps({ index })} onDelete={() => callBack && callBack(option, 'removeOptionClick')} />
                  ))
                }
                defaultValue={defaultValue || []}
                onChange={(event, newValue, reason) => callBack && callBack(newValue, reason)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...props}
                    variant="outlined"
                    label={label}
                    onChange={e => setValueState(e.target.value)}
                    onBlur={e => setValueState('')}
                  />
                )}
                renderOption={(props, option) => {
                  const dab = vals?.map(item => item?._id)?.includes(option?._id);
                  return (
                    <li
                      {...props}
                      key={option._id}
                      onClick={e => {
                        props.onClick(e)
                        callBack && callBack(option, 'selectOptionClick')
                      }}
                      style={{ borderBottom: '1px solid #ccc', pointerEvents: dab ? 'none' : 'default', opacity: dab ? '0.3' : '1' }}
                    >
                      <Grid container>
                        <Grid item xs={12} style={{
                          padding: '1px 0px',
                          cursor: 'pointer'
                        }}>
                          {
                            option?.[optionlabel]
                          }
                        </Grid>
                      </Grid>
                    </li>
                  )
                }}
              />}
            />
          </FormControl>
        )
      }
      {
        type === 'autocomplete-async' && (
          <FormControl fullWidth>
            <Controller
              name={name}
              control={control}
              render={({ field, formState }) => <Autocomplete
                {...field}
                error={!!get(formState.errors, name)}
                //getOptionDisabled={(option) => option.status?.name === 'Locked'}
                {...props}
                filterOptions={(x) => x}
                disablePortal
                onChange={(event, val, reason) => onClear(event, reason)}
                id={name}
                options={addNewOption ? [{ _id: 'add-new', [vName]: 'Add New' }, ...(uniqueFilterKey ? ((remoteOptions || []).length ? (remoteOptions || []) : (options || [])).reduce((unique, o) => {
                  if (!unique.some(obj => obj[uniqueFilterKey] === o[uniqueFilterKey])) {
                    unique.push(o);
                  }
                  return unique;
                }, []) : ((remoteOptions || []).length ? (remoteOptions || []) : (options || [])))] : (uniqueFilterKey ? ((remoteOptions || []).length ? (remoteOptions || []) : (options || [])).reduce((unique, o) => {
                  if (!unique.some(obj => obj[uniqueFilterKey] === o[uniqueFilterKey])) {
                    unique.push(o);
                  }
                  return unique;
                }, []) : ((remoteOptions || []).length ? (remoteOptions || []) : (options || [])))}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option[vName]
                }
                renderInput={(params) => <TextField
                  {...props}
                  {...params}
                  name={name}
                  label={label}
                  onChange={e => onChange(e)}
                  onBlur={e => handleBlur(e)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      key={option._id}
                      onClick={e => {
                        props.onClick(e)
                        optionSelected(option, name)
                      }}
                      style={option?._id === 'add-new' ? {
                        borderBottom: '1px dotted #fff',
                        background: '#000'
                      } : { borderBottom: '1px solid #707070' }}
                    >
                      <Grid container>
                        <Grid item xs={12} style={{
                          padding: '10px',
                          cursor: 'pointer'
                        }}>
                          {
                            mvName ? (
                              <>
                                {
                                  mvName.map(item => <>{option[item]}</>)
                                }
                                <span style={{ float: 'right' }}>{option.currentTripNumber ? `Assigned to ${option.currentTripNumber}` : ''}</span>
                              </>
                            ) : <>
                              {prefix || ''}
                              {option[vName]}{option?.city ? ` , ${option?.city}` : ""}{option?.state?._id ? ` , ${option?.state?.shortcode}` : ""}{option?.country?._id ? ` , ${option?.country?.shortCode}` : ""}
                              <span style={{ float: 'right' }}>
                                {option.currentTripNumber ? `Assigned to ${option.currentTripNumber}` : ''}
                              </span>
                            </>
                          }
                        </Grid>
                      </Grid>
                    </li>
                  )
                }}
              />}
            />
          </FormControl>
        )
      }
      {
        type === 'autocomplete-remote' && (
          <FormControl fullWidth>
            <Controller
              name={name}
              control={control}
              value={getValues(name)}
              render={({ field, formState }) => <Autocomplete
                {...field}
                error={!!get(formState.errors, name)}
                getOptionDisabled={(option) => option.active === 0}
                InputProps={{
                  startAdornment: startadornment ? <InputAdornment position="start">{startadornment}</InputAdornment> : "",
                  endAdornment: endadornment ? <InputAdornment position="start">{endadornment}</InputAdornment> : "",
                }}
                {...props}
                filterOptions={(x) => x}
                value={getValues(name)}
                disablePortal
                onChange={(event, val, reason) => onClear(event, reason)}
                id={name}
                options={uniqueFilterKey ? ((remoteOptions || []).length ? (remoteOptions || []) : (options || [])).reduce((unique, o) => {
                  if (!unique.some(obj => obj[uniqueFilterKey] === o[uniqueFilterKey])) {
                    unique.push(o);
                  }
                  return unique;
                }, []) : ((remoteOptions || []).length ? (remoteOptions || []) : (options || []))}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option[vName]
                }
                renderInput={(params) => <TextField
                  {...params}
                  placeholder={placeholder || ''}
                  name={name}
                  label={label}
                  value={getValues(name)}
                  //onChange={e => onChange(e)}
                  //onBlur={e => handleBlur(e)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      key={option._id}
                      onClick={e => {
                        props.onClick(e)
                        optionSelected(option, name)
                      }}
                      style={{ borderBottom: '1px solid #707070', cursor: 'pointer', fontSize: 14 }}
                    >
                      <Grid container style={{ padding: 10 }}>
                        {
                          mvName ? (
                            <>
                              <Grid item xs={3}>
                                {
                                  mvName.map(item => <>{item.includes('.') ? item.split('.').reduce((o, i) => o?.[i], option) : option[item]} - </>)
                                }
                                {option.currentTripNumber ? <span style={{ float: 'right' }}>{option.currentTripNumber ? `Assigned to ${option.currentTripNumber}` : ''}</span> : ""}
                              </Grid>
                              <Grid item xs={9}>
                                <span>
                                  {
                                    option?.location?.city || option?.location?.state || option?.location?.country ? (
                                      <PlaceIcon />
                                    ) : ""
                                  }
                                  {option?.location?.city ? ` ${option?.location?.city}` : ""}{option?.location?.state ? `, ${option?.location?.state}` : ""}{option?.location?.country ? `, ${option?.location?.country}` : ""}
                                </span>
                              </Grid>
                            </>
                          ) : <>
                            <Grid item xs={3}>
                              {option[vName]}
                            </Grid>
                            <Grid item xs={5}>
                              {
                                option?.city || option?.state?._id || option?.country?._id ? (
                                  <span>
                                    {
                                      option?.city ? (
                                        <PlaceIcon />
                                      ) : ""
                                    }
                                    <span>
                                      {option?.city ? `${option?.city}` : ""}{option?.state?.shortcode ? `, ${option?.state?.shortcode}` : ""}{option?.country?.shortCode ? `, ${option?.country?.shortCode}` : ""}
                                    </span>
                                  </span>
                                ) : (
                                  <span>
                                    {
                                      option?.location?.city || option?.location?.state || option?.location?.country ? (
                                        <PlaceIcon />
                                      ) : ""
                                    }
                                    {option?.location?.city ? ` ${option?.location?.city}` : ""}{option?.location?.state ? `, ${option?.location?.state}` : ""}{option?.location?.country ? `, ${option?.location?.country}` : ""}
                                  </span>
                                )
                              }
                            </Grid>
                            <Grid item xs={4}>
                              {
                                option.active === 0 ? (
                                  <span style={{ float: 'right' }}>Inactive</span>
                                ) : ""
                              }
                              <span>
                                {option.currentTripNumber ? `Assigned to ${option.currentTripNumber}` : ''}
                              </span>
                            </Grid>
                          </>
                        }
                      </Grid>
                    </li>
                  )
                }}
              />}
            />
          </FormControl>
        )
      }
      {
        type === 'radiogroup' && (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <Controller
              name={name}
              control={control}
              render={({ field, formState }) => <RadioGroup
                {...field}
                row
                aria-label={label}
                name={name}
                onChange={event => {
                  pName ? setValue(pName, options.filter(item => item.value == event.target.value)[0]) : setValue(name, event.target.value);

                  callBack && callBack(event.target.value)
                }}
                error={!!get(formState.errors, name)}
                defaultValue={defaultValue}
              >
                <Grid container spacing={2}>
                  {
                    (options || []).map((item, index) => (
                      <Grid item xs={xs || 6}>
                        <StyledDiv>
                          <FormControlLabel value={item.value} control={<Radio disabled={item.disabled} />}
                            label={<>{item.label} {item.svgLink ? (<CustomIcon svgLink={item.svgLink} />) : ""}</>}
                            key={`${item.value}-${index}`} />
                          {
                            item.subText && (
                              <>
                                <div className='sub-text'>{item.subText}</div>
                              </>
                            )
                          }
                        </StyledDiv>
                      </Grid>
                    ))
                  }
                  {
                    extensionComp ?? extensionComp
                  }
                </Grid>
              </RadioGroup>}
            />
          </FormControl>
        )
      }
      {
        type === 'checkboxgroup' && (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <Controller
              name={name}
              control={control}
              render={({ field, formState }) => <FormGroup
                {...field}
                error={!!get(formState.errors, name)}
                row
                aria-label={label}
                onChange={event => {
                  checkToVal ? setValue(name, event.target.checked) : setValue(name, { ...(getValues(name) || {}), [event.target.name]: event.target.checked })
                }}
              >
                <Grid container spacing={2}>
                  {
                    (options || []).map((item, index) => {
                      return (
                        <Grid item xs={xs || 12}>
                          <StyledDiv>
                            <FormControlLabel
                              control={
                                <Checkbox name={item.name} checked={checkToVal ? getValues(name) : ((getValues(name) || {})?.[item.name]) || false} />
                              }
                              label={item.label}
                              key={`${item.value}-${index}`}
                              labelPlacement="end"
                            />
                          </StyledDiv>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </FormGroup>}
            />
          </FormControl>
        )
      }
      {
        type === 'date-picker' && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl fullWidth >
              <Controller
                name={name}
                control={control}
                render={({ field, formState }) => <DatePicker
                  {...props}
                  {...field}
                  inputFormat="MM/dd/yyyy"
                  onChange={handleChange}
                  renderInput={(params) => <TextField
                    {...field}
                    {...params}
                    {...props}
                    error={!!get(formState.errors, name)}
                    name={name}
                  />}
                />
                }
              />
            </FormControl>
          </LocalizationProvider>
        )
      }
      {
        type === 'datetime-picker' && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl fullWidth >
              <Controller
                name={name}
                control={control}
                render={({ field, formState }) => <DateTimePicker
                  {...props}
                  {...field}
                  inputFormat="MM/dd/yyyy hh:mm a"
                  onChange={handleChange}
                  renderInput={(params) => <TextField
                    {...field}
                    {...params}
                    {...props}
                    error={!!get(formState.errors, name)}
                    name={name}
                  />}
                />
                }
              />
            </FormControl>
          </LocalizationProvider>
        )
      }
      {
        type === 'time-picker' && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl fullWidth >
              <Controller
                name={name}
                control={control}
                render={({ field, formState }) => <TimePicker
                  {...props}
                  {...field}
                  inputFormat="hh:mm a"
                  onChange={handleChange}
                  renderInput={(params) => <TextField
                    name={name}
                    {...params}
                    {...field}
                    error={!!get(formState.errors, name)}
                  />}
                />
                }
              />
            </FormControl>
          </LocalizationProvider>
        )
      }
      {
        type === 'text-remote' && (
          <FormControl fullWidth>
            <Controller
              name={name}
              control={control}
              onChange={e => console.log(e.target.value)}
              render={({ field, formState }) => <TextField
                {...field}
                error={!!get(formState.errors, name)}
                {...props}
                onChange={e => {
                  field.onChange(e);
                  onChange(e);
                }}
                onBlur={e => handleBlur(e)}
                id={name}
              />}
            />
          </FormControl>
        )
      }
    </>
  );
}
