import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import React from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useFormContext } from 'react-hook-form';

const StyledDiv = styled('div')(({ theme }) => ({
  "& .DraftEditor-root": {
    borderRadius: 4,
    cursor: 'text',
    border: `1px solid #515253`,
    padding: '5px 10px',
    ":hover": {
      border: `1px solid #fff`,
    },
    ":focus-within": {
      border: `1px solid ${theme.palette.primary.main}`,
    }
  },
  "& .Menubar-main": {
    background: '#000',
    color: '#fff',
    "& .rdw-link-modal, .rdw-colorpicker-modal, .rdw-dropdown-optionwrapper, .rdw-embedded-modal, .rdw-image-modal": {
      color: '#000'
    }
  },
  "& .ProseMirror": {
    minHeight: 500,
    borderRadius: 5,
    marginTop: 5,
    border: '0.1px solid #ccc',
    outline: 'none',
    padding: 15,
    textAlign: 'left'
  },
  "& p,h1,h2,h3,h4,h5,b": {
    padding: 0,
    margin: 0
  },
  "& .txt-btn": {
    fontSize: 17,
    fontWeight: 'bolder'
  },
  "& button": {
    margin: 2
  }
}));

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  return (
    <>
      <Button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        variant={editor.isActive('bold')? 'contained' : 'outlined'}
        color="primary"
      >
        bold
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        variant={editor.isActive('italic')? 'contained' : 'outlined'}
        color="primary"
      >
        italic
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        variant={editor.isActive('strike')? 'contained' : 'outlined'}
        color="primary"
      >
        strike
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleCode()
            .run()
        }

        variant={editor.isActive('code')? 'contained' : 'outlined'}
        color="primary"
      >
        code
      </Button>
      <Button color='primary'  variant='outlined' onClick={() => editor.chain().focus().unsetAllMarks().run()}>
        clear marks
      </Button>
      <Button color="primary"  variant='outlined' onClick={() => editor.chain().focus().clearNodes().run()}>
        clear nodes
      </Button>
      <Button
        onClick={() => editor.chain().focus().setParagraph().run()}

        variant={editor.isActive('paragraph')? 'contained' : 'outlined'}
        color="primary"
      >
        paragraph
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}

        variant={editor.isActive('heading', { level: 1 })? 'contained' : 'outlined'}
        color="primary"
      >
        h1
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}

        variant={editor.isActive('heading', { level: 2 })? 'contained' : 'outlined'}
        color="primary"
      >
        h2
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}

        variant={editor.isActive('heading', { level: 3 })? 'contained' : 'outlined'}
        color="primary"
      >
        h3
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}

        variant={editor.isActive('heading', { level: 4 })? 'contained' : 'outlined'}
        color="primary"
      >
        h4
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}

        variant={editor.isActive('heading', { level: 5 })? 'contained' : 'outlined'}
        color="primary"
      >
        h5
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}

        variant={editor.isActive('heading', { level: 6 })? 'contained' : 'outlined'}
        color="primary"
      >
        h6
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleBulletList().run()}

        variant={editor.isActive('bulletList')? 'contained' : 'outlined'}
        color="primary"
      >
        bullet list
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}

        variant={editor.isActive('orderList')? 'contained' : 'outlined'}
        color="primary"
      >
        ordered list
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}

        variant={editor.isActive('codeblock')? 'contained' : 'outlined'}
        color="primary"
      >
        code block
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}

        variant={editor.isActive('blockquote')? 'contained' : 'outlined'}
        color="primary"
      >
        blockquote
      </Button>
      <Button color='primary'  variant='outlined' onClick={() => editor.chain().focus().setHorizontalRule().run()}>
        horizontal rule
      </Button>
      <Button color='primary'  variant='outlined' onClick={() => editor.chain().focus().setHardBreak().run()}>
        hard break
      </Button>
      <Button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .undo()
            .run()
        }

        color='primary'
        variant='outlined'
      >
        undo
      </Button>
      <Button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .redo()
            .run()
        }

        color='primary'
        variant='outlined'
      >
        redo
      </Button>
    </>
  )
}

export default ({ name, html }) => {
  const { setValue } = useFormContext();

  const editor = useEditor({
    onUpdate({ editor }) {
      name && setValue(name, editor.getHTML());
      name && setValue(`${name}JSON`, editor?.getJSON());
      console.log(editor?.getJSON());
    },
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: html,
  });

  React.useEffect(() => {
    editor?.commands.setContent(html);
    name && setValue(name, html);
  }, [html]);

  return (
    <StyledDiv>
      <MenuBar editor={editor} />
      <EditorContent editor={editor}/>
    </StyledDiv>
  )
}