import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import LanguageIcon from "@mui/icons-material/Language";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  InputFields,
  NavButtonGroup,
  Backdrop,
  Editor,
  UploadBox,
  PreviewBox,
} from "../../Components";

import { updateSnackbar } from "../../store/snackbar";
import { getCollege, setCollege, updateCollege } from "../../store/colleges";
import { createTag, getTags } from "../../store/tags";
import { getCollegeCourses } from "../../store/courses";

const StyledDiv = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: 100,
  "& .details": {
    padding: 10,
  },
  "& .title": {
    backgroundColor: theme.palette.grey[200],
    marginTop: 10,
  },
  "& .detail-box": {
    padding: 5,
    "& small": {
      color: theme.palette.grey[700],
    },
    "& span": {
      color: theme.palette.primary.main,
    },
  },
  "& .details-right": {
    textAlign: "right",
  },
  "& .details-middle": {
    textAlign: "center",
  },
}));

const BasicDetails = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = React.useState(false);
  const { college, errorList } = useSelector((state) => state.colleges);

  const methods = useForm({
    defaultValues: college,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data) => {
    await dispatch(updateCollege(data));
    !errorList.length
      ? dispatch(
          updateSnackbar({
            status: true,
            message: ["College updated successfully"],
            type: "success",
          })
        )
      : dispatch(
          updateSnackbar({
            status: true,
            message: errorList,
            type: "error",
          })
        );
    setEdit(false);
  };

  React.useEffect(() => {
    reset(college);
  }, [college]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12} className="title">
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{ flexGrow: 1 }}
            >
              Basic Details
              {/* {edit ? (
                <>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    startIcon={<SaveIcon color="success" />}
                    style={{ float: "right", marginRight: 5 }}
                    variant="outlined"
                    color="primary"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => setEdit(false)}
                    startIcon={<ClearIcon color="error" />}
                    style={{ float: "right", marginRight: 5 }}
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => setEdit(true)}
                  startIcon={<EditIcon color="secondary" />}
                  style={{ float: "right", marginRight: 5 }}
                  variant="outlined"
                  color="primary"
                >
                  Edit
                </Button>
              )} */}
            </Typography>
          </Grid>
          {edit ? (
            <>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="fullName"
                  label="Full Name"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="phone"
                  label="Phone"
                  type="number"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="email"
                  label="Email"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="targetCountry"
                  label="Target Country"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="collegeName"
                  label="College Name"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="courseName"
                  label="Course Name"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="courseFee"
                  label="Course Fee"
                  type="number"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="loanAmount"
                  label="Loan Amount"
                  type="number"
                  size="small"
                />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <InputFields
                  name="bankName"
                  label="Bank Name"
                  type="text"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} className="detail-box">
                <InputFields
                  name="comments"
                  label="Comments"
                  type="text"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} className="detail-box">
                <small>College Source</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.source?.toUpperCase() || ""}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box">
                <small>Slot Date</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college.cbSchedule
                    ? moment(college.cbSchedule).format("ll")
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={4} className="detail-box">
                <small>Slot Time</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college.timeSlotFrom && college.timeSlotTo
                    ? `${moment(college.timeSlotFrom).format("LT")} - ${moment(
                        college.timeSlotTo
                      ).format("LT")}`
                    : ""}
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={3} className="detail-box">
                <small>College Name</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.name || ""}
                </Typography>
              </Grid>
              <Grid item xs={3} className="detail-box">
                <small>Address</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.address?.fullAddress}
                </Typography>
              </Grid>
              <Grid item xs={3} className="detail-box">
                <small>Acceptance Rate</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.acceptanceRate ? `${college?.acceptanceRate}%` : ""}
                </Typography>
              </Grid>
              <Grid item xs={3} className="detail-box">
                <small>Avg. Tution Fee</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.avgTuitionFee
                    ? `Rs. ${college?.avgTuitionFee} ${college?.avgTuitionFeeUnit}/Yr`
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={3} className="detail-box">
                <small>Avg. Living Fee</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.avgLivingFee
                    ? `Rs. ${college?.avgLivingFee} ${college?.avgLivingFeeUnit}/Yr`
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={3} className="detail-box">
                <small>Top Course</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.topCourse || ""}
                </Typography>
              </Grid>
              <Grid item xs={3} className="detail-box">
                <small>Top Course Fee</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.topCourseFee
                    ? `Rs. ${college?.topCourseFee} ${college?.topCourseFeeUnit}/Yr`
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={3} className="detail-box">
                <small>Course Duration</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.topCourseDuration
                    ? `${college?.topCourseDuration} ${college?.topCourseDurationUnit}`
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={3} className="detail-box">
                <small>CD Rank</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.cdRank || ""}
                </Typography>
              </Grid>
              <Grid item xs={3} className="detail-box">
                <small>QS Rank</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.rankByQS || ""}
                </Typography>
              </Grid>
              <Grid item xs={3} className="detail-box">
                <small>THE Rank</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.rankByThe || ""}
                </Typography>
              </Grid>
              <Grid item xs={3} className="detail-box">
                <small>Top Course Eligibility</small>
                <br />
                <Typography variant="body1" gutterBottom>
                  {college?.topCourseEligibility
                    ?.map((item) => `${item?.testName} ${item?.testScore}`)
                    ?.join(" | ")}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};

const CoursesDetails = () => {
  const dispatch = useDispatch();
  const { _id } = useParams();
  const { courses, errorList, loading } = useSelector((state) => state.courses);

  React.useEffect(() => {
    dispatch(getCollegeCourses(_id));
  }, []);

  React.useEffect(() => {
    if (errorList?.length) {
      dispatch(
        updateSnackbar({
          status: true,
          message: errorList,
          type: "error",
        })
      );
    }
  }, [errorList]);

  return (
    <>
      {!loading ? (
        <Grid container spacing={1}>
          <Grid item xs={12} className="title">
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{ flexGrow: 1 }}
            >
              Course Details
            </Typography>
          </Grid>
          {courses?.map((course) => (
            <Grid item xs={12}>
              <Card sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      sx={{ fontSize: 20 }}
                      color="primary"
                      gutterBottom
                    >
                      {course?.courseName}{" "}
                      <Chip
                        label={`${course?.courseDuration?.duration} ${course?.courseDuration?.durationUnit}`}
                        color="primary"
                        size="small"
                      />
                    </Typography>
                    <Typography
                      sx={{ fontSize: 17 }}
                      color="text.primary"
                      gutterBottom
                    >
                      <Stack direction="row">
                        <div style={{ marginTop: 3 }}>
                          <LanguageIcon fontSize="small" />
                        </div>{" "}
                        <div style={{ marginLeft: 5 }}>
                          {course?.languages?.join(", ")}
                        </div>
                        <div style={{ marginLeft: 15 }}>|</div>
                        <div style={{ marginLeft: 15 }}>
                          <LocationCityIcon />
                        </div>
                        <div style={{ marginLeft: 5 }}>
                          {course?.campus?.join(", ")}
                        </div>
                        <div style={{ marginLeft: 15 }}>-</div>
                        <div style={{ marginLeft: 15 }}>
                          <b>{course?.courseType}</b>
                        </div>
                      </Stack>
                    </Typography>
                  </CardContent>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      sx={{ fontSize: 17 }}
                      color="text.primary"
                      gutterBottom
                    >
                      {course?.examScores
                        ?.map((eScore) => `${eScore?.name}: ${eScore?.score}`)
                        ?.join(" | ")}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 17 }}
                      color="text.primary"
                      gutterBottom
                    >
                      {course?.fees
                        ?.map(
                          (fee) =>
                            `${fee?.currency} ${fee?.fee} ${fee.feeUnit}/${fee.feeUnitDuration}`
                        )
                        ?.join(" | ")}
                    </Typography>
                  </CardContent>
                </Box>
                {/* <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions> */}
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        "Loading..."
      )}
    </>
  );
};

const ViewCollege = () => {
  const dispatch = useDispatch();
  const { _id } = useParams();
  const { errorList, colleges, college } = useSelector(
    (state) => state.colleges
  );
  const [loading, setLoading] = React.useState(true);
  const tags = useSelector((state) => state.tags.tags);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: college,
  });

  const { watch, setValue, reset } = methods;

  const onSubmit = async (data) => {
    await dispatch(updateCollege({ _id, ...data }));
    navigate(`/colleges`);
  };

  React.useEffect(() => {
    loading ? setOpen(true) : setOpen(false);
  }, [loading]);

  React.useEffect(() => {
    if (errorList?.length) {
      dispatch(
        updateSnackbar({
          status: true,
          message: errorList,
          type: "error",
        })
      );
    }
  }, [errorList]);

  React.useEffect(() => {
    dispatch(getTags());
  }, []);

  React.useEffect(() => {
    const currentCollege = colleges.find((college) => college._id === _id)?.[0];

    const getCurrentCollege = async () => {
      if (currentCollege) {
        await dispatch(setCollege(currentCollege));
      } else {
        const collegeData = await dispatch(getCollege(_id));
        reset(collegeData?.payload);
        dispatch(setCollege(collegeData?.payload));
      }
      setLoading(false);
    };

    getCurrentCollege();
  }, [_id]);

  React.useEffect(() => {
    setValue("slug", watch("title")?.toLowerCase()?.split(" ")?.join("-"));
  }, [watch("title")]);

  React.useEffect(() => {
    setValue("tags", college?.tags);
  }, [college?.tags?.length]);

  return (
    <StyledDiv>
      <Backdrop open={open} />
      {loading && watch("tags") === undefined ? (
        <div>Loading...</div>
      ) : (
        <>
          <BasicDetails />
          <CoursesDetails />
        </>
        // <FormProvider {...methods}>
        //   <form>
        //     <Grid container spacing={2}>
        //       <Grid item xs={12}>
        //         <Typography variant="h5" gutterBottom>
        //           College Details
        //         </Typography>
        //       </Grid>
        //       <Grid item xs={6}>
        //         <InputFields
        //           name="name"
        //           label="College Title"
        //           type="text"
        //           size="small"
        //           placeholder="eg. Education Loan in India"
        //         />
        //       </Grid>
        //       <Grid item xs={6}>
        //         <InputFields
        //           name="slug"
        //           label="College Slug"
        //           type="text"
        //           size="small"
        //           placeholder="education-loan-in-india"
        //         />
        //       </Grid>
        //       <Grid item xs={6}>
        //         <InputFields
        //           name="breadCrumb"
        //           label="Bread Crumb Text"
        //           type="text"
        //           size="small"
        //           placeholder="Education Loan in India"
        //         />
        //       </Grid>
        //       <Grid item xs={6}>
        //         <InputFields
        //           name="tagsList"
        //           label="Tags"
        //           type="autocomplete-multiple"
        //           size="small"
        //           options={tags?.map((item) => ({
        //             ...item,
        //             label: item.name,
        //             value: item._id,
        //           }))}
        //           vals={watch("tags")}
        //           defaultValue={watch("tags")}
        //           placeholder="education"
        //           optionlabel="name"
        //           callBack={(data, reason) => {
        //             if (reason === "selectOptionClick") {
        //               setValue("tags", [...watch("tags"), data]);
        //             } else if (reason === "removeOptionClick") {
        //               setValue(
        //                 "tags",
        //                 watch("tags").filter((tag) => tag._id !== data._id)
        //               );
        //             } else if (reason === "clear") {
        //               setValue("tags", []);
        //             }
        //           }}
        //           addNewOptionFly
        //           addNewOptionFlyCB={async (name) => {
        //             const tagData = await dispatch(createTag({ name }));
        //             setValue("tags", [...watch("tags"), tagData?.payload]);
        //           }}
        //         />
        //       </Grid>
        //       <Grid item xs={6}>
        //         <InputFields
        //           name="description"
        //           label="College Description"
        //           type="text"
        //           size="small"
        //           multiline
        //           rows={4}
        //           placeholder="eg. Education Loan in India"
        //         />
        //       </Grid>
        //       <Grid item xs={6}>
        //         <InputFields
        //           name="metaTitle"
        //           label="College Meta Title"
        //           type="text"
        //           size="small"
        //           placeholder="eg. Education Loan in India"
        //         />
        //       </Grid>
        //       <Grid item xs={6}>
        //         <InputFields
        //           name="metaDescription"
        //           label="College Meta Description"
        //           type="text"
        //           size="small"
        //           multiline
        //           rows={4}
        //           placeholder="eg. Education Loan in India"
        //         />
        //       </Grid>
        //       <Grid item xs={12}>
        //         <UploadBox
        //           boxText="Upload College banner image"
        //           reftype="college"
        //           refId={null}
        //           multiple={false}
        //           uploadCallBack={async (uploadedFiles) => {
        //             setValue("logo", uploadedFiles[0]);
        //           }}
        //           accept={{
        //             "image/*": [".jpeg", ".jpg", ".png"],
        //           }}
        //         />
        //       </Grid>
        //       {watch("logo")?.location && (
        //         <Grid item xs={12}>
        //           <PreviewBox file={watch("logo")} />
        //         </Grid>
        //       )}
        //       <Grid item xs={12}>
        //         <h2>College Content</h2>
        //       </Grid>
        //       <Grid item xs={12}>
        //         <Editor html={college?.content} name="content" />
        //       </Grid>
        //       <Grid item xs={12}>
        //         <Stack spacing={2} direction="row">
        //           <NavButtonGroup
        //             buttons={[0, 1]}
        //             nextText="Update College as Draft"
        //             fullWidth
        //             callBack={() => onSubmit({ ...watch(), status: 1 })}
        //           />
        //           <NavButtonGroup
        //             buttons={[0, 1]}
        //             nextText="Update College and Publish"
        //             fullWidth
        //             callBack={() => onSubmit({ ...watch(), status: 2 })}
        //           />
        //         </Stack>
        //       </Grid>
        //       <Grid item xs={12}>
        //         <br />
        //         <br />
        //       </Grid>
        //     </Grid>
        //   </form>
        // </FormProvider>
      )}
    </StyledDiv>
  );
};

export default ViewCollege;
