import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import { request } from '../utils/API';

const initialState = {
  lead: {},
  leads: [],
  errorList: [],
  logs: [],
  loading: false
}

export const getLeads = createAsyncThunk('leads/get', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/leads',
      method: 'get'
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch leads data. Please try again later!'])
  }
});

export const getLead = createAsyncThunk('lead/get', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: `/leads/${args}`,
      method: 'get'
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch leads data. Please try again later!'])
  }
});

export const createLead = createAsyncThunk('lead/create', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: `/leads`,
      method: 'post',
      body: args
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch leads data. Please try again later!'])
  }
});

export const updateLead = createAsyncThunk('lead/update', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: `/leads/${args._id}`,
      method: 'put',
      body: args
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch leads data. Please try again later!'])
  }
});

export const getLeadLogs = createAsyncThunk('lead/logs', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: `/logs/${args}`,
      method: 'get'
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch leads data. Please try again later!'])
  }
});

export const updateMedia = createAsyncThunk('media/update', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: `/media/${args._id}`,
      method: 'put',
      body: args
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch leads data. Please try again later!'])
  }
});

export const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setLead: (state, action) => {
      state.lead = action.payload;
    },
    removeDocsFromLead: (state, action) => {
      state.lead.documents = state.lead?.documents?.filter(item => item._id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    // Create Lead
    builder.addCase(createLead.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createLead.fulfilled, (state, action) => {
      state.leads = [action.payload, ...state.leads];
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(createLead.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Get list of leads
    builder.addCase(getLeads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLeads.fulfilled, (state, action) => {
      state.leads = action.payload;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getLeads.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Get single lead
    builder.addCase(getLead.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLead.fulfilled, (state, action) => {
      state.lead = action.payload;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getLead.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Update single lead
    builder.addCase(updateLead.fulfilled, (state, action) => {
      state.lead = action.payload;
      state.errorList = [];
    });
    builder.addCase(updateLead.rejected, (state, action) => {
      state.errorList = action.payload;
    });

    // Get lead logs
    builder.addCase(getLeadLogs.fulfilled, (state, action) => {
      state.logs = action.payload;
      state.errorList = [];
    });
    builder.addCase(getLeadLogs.rejected, (state, action) => {
      state.errorList = action.payload;
    });

    // Update media
    builder.addCase(updateMedia.fulfilled, (state, action) => {
      state.lead.documents = state.lead.documents.map(item => item._id === action.payload._id ? action.payload : item);
      state.errorList = [];
    });
    builder.addCase(updateMedia.rejected, (state, action) => {
      state.errorList = action.payload;
    });
  }
});

export const { setLead, removeDocsFromLead } = leadsSlice.actions

export default leadsSlice.reducer