import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  InputFields,
  NavButtonGroup,
  Backdrop,
  Editor,
  UploadBox,
  PreviewBox,
} from '../../Components';

import { updateSnackbar } from '../../store/snackbar';
import { getBlog, setBlog, updateBlog } from '../../store/blogs';
import { createTag, getTags } from '../../store/tags';

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
}));

const ViewBlog = () => {
  const dispatch = useDispatch();
  const { _id } = useParams();
  const { errorList, blogs, blog } = useSelector(state => state.blogs);
  const [loading, setLoading] = React.useState(true);
  const tags = useSelector(state => state.tags.tags);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: blog
  });

  const { watch, setValue, reset } = methods;

  const onSubmit = async data => {
    await dispatch(updateBlog({ _id, ...data }));
    navigate(`/blogs`);
  }

  React.useEffect(() => {
    loading ? setOpen(true) : setOpen(false);
  }, [loading]);

  React.useEffect(() => {
    if (errorList?.length) {
      dispatch(updateSnackbar({
        status: true,
        message: errorList,
        type: 'error'
      }));
    }
  }, [errorList]);

  React.useEffect(() => {
    dispatch(getTags())
  }, []);

  React.useEffect(() => {
    const currentBlog = blogs.find(blog => blog._id === _id)?.[0];

    const getCurrentBlog = async () => {
      if (currentBlog) {
        await dispatch(setBlog(currentBlog));
      } else {
        const blogData = await dispatch(getBlog(_id));
        reset(blogData?.payload);
        dispatch(setBlog(blogData?.payload));
      }
      setLoading(false);
    }

    getCurrentBlog();
  }, [_id]);

  React.useEffect(() => {
    setValue('slug', watch('title')?.toLowerCase()?.split(' ')?.join('-'));
  }, [watch('title')]);

  React.useEffect(() => {
    setValue('tags', blog?.tags);
  }, [blog?.tags?.length]);

  return (
    <StyledDiv>
      <Backdrop open={open} />
      {
        loading && watch('tags') === undefined ? (
          <div>Loading...</div>
        ) : (
          <FormProvider {...methods}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom>
                    Create a new Blog
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputFields
                    name="title"
                    label="Blog Title"
                    type="text"
                    size="small"
                    placeholder="eg. Education Loan in India"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputFields
                    name="slug"
                    label="Blog Slug"
                    type="text"
                    size="small"
                    placeholder="education-loan-in-india"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputFields
                    name="breadCrumb"
                    label="Bread Crumb Text"
                    type="text"
                    size="small"
                    placeholder="Education Loan in India"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputFields
                    name="tagsList"
                    label="Tags"
                    type="autocomplete-multiple"
                    size="small"
                    options={tags?.map(item => ({ ...item, label: item.name, value: item._id }))}
                    vals={watch('tags')}
                    defaultValue={watch('tags')}
                    placeholder="education"
                    optionlabel='name'
                    callBack={(data, reason) => {
                      if (reason === 'selectOptionClick') {
                        setValue('tags', [...watch('tags'), data]);
                      } else if (reason === 'removeOptionClick') {
                        setValue('tags', watch('tags').filter(tag => tag._id !== data._id));
                      } else if (reason === 'clear') {
                        setValue('tags', []);
                      }
                    }}
                    addNewOptionFly
                    addNewOptionFlyCB={async name => {
                      const tagData = await dispatch(createTag({ name }));
                      setValue('tags', [...watch('tags'), tagData?.payload]);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputFields
                    name="description"
                    label="Blog Description"
                    type="text"
                    size="small"
                    multiline
                    rows={4}
                    placeholder="eg. Education Loan in India"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputFields
                    name="metaTitle"
                    label="Blog Meta Title"
                    type="text"
                    size="small"
                    placeholder="eg. Education Loan in India"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputFields
                    name="metaDescription"
                    label="Blog Meta Description"
                    type="text"
                    size="small"
                    multiline
                    rows={4}
                    placeholder="eg. Education Loan in India"
                  />
                </Grid>
                <Grid item xs={12}>
                  <UploadBox
                    boxText='Upload Blog banner image'
                    reftype='blog'
                    refId={null}
                    multiple={false}
                    uploadCallBack={async uploadedFiles => {
                      setValue('banner', uploadedFiles[0]);
                    }}
                    accept={{
                      'image/*': ['.jpeg', '.jpg', '.png']
                    }}
                  />
                </Grid>
                {
                  watch('banner')?.location && (
                    <Grid item xs={12}>
                      <PreviewBox
                        file={watch('banner')}
                      />
                    </Grid>
                  )
                }
                <Grid item xs={12}><h2>Blog Content</h2></Grid>
                <Grid item xs={12}>
                  <Editor
                    html={blog?.content}
                    name="content"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={2} direction="row">
                    <NavButtonGroup
                      buttons={[0, 1]}
                      nextText="Update Blog as Draft"
                      fullWidth
                      callBack={() => onSubmit({ ...watch(), status: 1 })}
                    />
                    <NavButtonGroup
                      buttons={[0, 1]}
                      nextText="Update Blog and Publish"
                      fullWidth
                      callBack={() => onSubmit({ ...watch(), status: 2 })}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}><br /><br /></Grid>
              </Grid>
            </form>
          </FormProvider>
        )
      }
    </StyledDiv>
  )
}

export default ViewBlog;