import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import { getLeads } from '../../store/leads';

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
}));

const columns = [
  { field: 'id', headerName: 'Lead Id', width: 100, renderCell: params => `LEAD-${params?.value}` },
  { field: 'fullName', headerName: 'Full name' },
  { field: 'phone', headerName: 'Phone Number', width: 110 },
  { field: 'email', headerName: 'Email', width: 150 },
  { field: 'targetCountry', headerName: 'Country' },
  { field: 'cbSchedule', headerName: 'Slot Date', width: 100, renderCell: params => params?.value ? moment(params?.value).format('ll') : 'NA' },
  {
    field: 'slot', headerName: 'Time Slot', width: 150, valueGetter: (params) =>
      `${params.row.timeSlotFrom ? moment(params.row.timeSlotFrom).format('LT') : ''} - ${params.row.timeSlotFrom ? moment(params.row.timeSlotTo).format('LT') : ''}`
  },
  { field: 'source', headerName: 'Lead Source', width: 150, renderCell: params => params?.value?.toUpperCase() },
  { field: 'subSource', headerName: 'Lead SubSource', width: 150, renderCell: params => params?.value?.toUpperCase() },
  { field: 'leadStatus', headerName: 'Lead Status', width: 150, renderCell: params => params?.value?.toUpperCase() },
  {
    field: '_id', headerName: 'Actions', renderCell: params => (
      <Stack direction="row">
        <NavLink
          to={`/leads/${params.value}`}
        >
          <IconButton>
            <RemoveRedEyeOutlined color="primary" />
          </IconButton>
        </NavLink>
      </Stack>
    )
  }
];

const Leads = () => {
  const dispatch = useDispatch();
  const leads = useSelector((state) => state.leads.leads);

  React.useEffect(() => {
    dispatch(getLeads());
  }, []);

  return (
    <StyledDiv>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NavLink
            to="/leads/create"
          >
            <Button startIcon={<AddIcon />} variant="outlined">
              Create New Lead
            </Button>
          </NavLink>
          <NavLink
            to="/leads/bulk-upload"
            style={{ marginLeft: 10 }}
          >
            <Button startIcon={<CloudUploadIcon />} variant="outlined">
              Bulk Upload Lead
            </Button>
          </NavLink>
        </Grid>
        <Grid item xs={12} style={{ height: '83vh', width: '100%' }}>
          <DataGrid
            rows={leads.map(lead => ({ ...lead, id: lead.id || lead._id }))}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            checkboxSelection
          />
        </Grid>
      </Grid>
    </StyledDiv >
  )
}

export default Leads;