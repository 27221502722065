import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import { updateSnackbar } from '../../store/snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbar = () => {
  const dispatch = useDispatch();
  const snackBar = useSelector(state => state.snackbar);
  const [open, setOpen] = React.useState(snackBar.status || false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(updateSnackbar(snackBar.type, snackBar.message, false));
  };

  React.useEffect(() => {
    setOpen(snackBar.status || false);
  }, [snackBar.status]);

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={Slide}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={5000}
      >
        <Alert onClose={handleClose} severity={snackBar.type} sx={{ width: '100%' }}>
          {
            snackBar?.title ? (<div>
              <b>{snackBar?.title}</b>
            </div>
            ) : ""
          }
          {
            snackBar?.message?.map(item => (
              <div key={item}>
                <span>{item}</span><br />
              </div>
            ))
          }
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default CustomizedSnackbar;