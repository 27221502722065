import { configureStore } from "@reduxjs/toolkit";
import snackBarReducer from "./snackbar";
import usersReducer from "./users";
import leadsReducer from "./leads";
import blogsReducer from "./blogs";
import tagsReducer from "./tags";
import collegeReducer from "./colleges";
import courseReducer from "./courses";

export const store = configureStore({
  reducer: {
    snackbar: snackBarReducer,
    users: usersReducer,
    leads: leadsReducer,
    blogs: blogsReducer,
    tags: tagsReducer,
    colleges: collegeReducer,
    courses: courseReducer,
  },
});
