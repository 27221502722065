import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import BookIcon from "@mui/icons-material/Book";
import SchoolIcon from "@mui/icons-material/School";

import { MainRoutes } from "../../routes";
import { logout } from "../../store/users";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const StyledList = styled(List)(({ theme }) => ({
  "& a": {
    textDecoration: "none",
  },
  "& .active-menu": {
    color: theme.palette.secondary.main,
    "& .MuiListItem-root": {
      borderLeft: `5px solid ${theme.palette.primary.main}`,
      borderRight: `10px solid ${theme.palette.primary.main}`,
      height: 60,
    },
  },
  "& .inactive-menu": {
    color: "#4b4c4b",
    "& .MuiListItem-root": {
      height: 60,
    },
  },
  "& .notifications": {
    zIndex: 99999,
    marginTop: -40,
  },
  "& .MuiListItemText-primary": {
    fontSize: "90%",
    fontWeight: "bolder",
  },
}));

let menuItems = [
  {
    label: "DASHBOARD",
    link: "/dashboard",
    icon: <DashboardIcon color="primary" />,
  },
  {
    label: "LEADS",
    link: "/leads",
    icon: <SupervisedUserCircleIcon color="primary" />,
  },
  {
    label: "BLOGS",
    link: "/blogs",
    icon: <BookIcon color="primary" />,
  },
  {
    label: "COLLEGES",
    link: "/colleges",
    icon: <SchoolIcon color="primary" />,
  },
  {
    label: "SETTINGS",
    link: "/settings",
    icon: <SettingsIcon color="primary" />,
  },
];

const Dashboard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const currentUser = useSelector((state) => state.users.currentUser);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <b>Welcome!</b> {currentUser.fullName}
          </Typography>
          <IconButton onClick={() => dispatch(logout())}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            src="https://klassfin.s3.ap-south-1.amazonaws.com/logo.png"
            width="50px"
            style={{ marginRight: "35%" }}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <StyledList>
          {menuItems.map((item, index) => (
            <NavLink
              end={
                item.link.includes("/settings") ||
                item.link.includes("/expenses") ||
                item.link.includes("/finance")
                  ? true
                  : false
              }
              key={item.label}
              to={item.link}
              className={({ isActive }) =>
                isActive ? "active-menu menu" : "inactive-menu menu"
              }
            >
              <ListItem>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} className="menu-item" />
              </ListItem>
            </NavLink>
          ))}
        </StyledList>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <div style={{ height: "650px" }}>
          <MainRoutes />
        </div>
      </Box>
    </Box>
  );
};

export default Dashboard;
