import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import * as XLSX from 'xlsx';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import _ from 'lodash';
import {
  useFormContext
} from "react-hook-form";

import {
  NavButtonGroup
} from '../../Components';
import { make_cols } from './MakeColumns';
import { Button } from '@mui/material';
import { request } from '../../utils/API';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#000",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#fff'
}));

const allowedTypes = [
  "text/csv",
  "application/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.macroEnabled.12"
];

const TableHeader = props => {
  const { rowData, cols } = props;
  // const [options, setOptions] = React.useState(cols.map(item => excelItems.columns) || []);

  // const callBack = (data, index, name) => {
  //   let colIndex = null;

  //   cols.map((item, cIndex) => {
  //     if (item.name === name) colIndex = cIndex;
  //     return item;
  //   })
  //   setOptions(options.map((itm, indx) => indx !== colIndex ? itm.filter(im => im.value !== data.value) : itm));
  // }

  // React.useEffect(() => {
  //   setOptions(cols.map(item => excelItems.columns));
  // }, [cols.length]);

  return (
    <>
      {
        Object.keys(rowData[0]).filter(item => item !== '_id').map((item, index) => (
          <StyledTableCell align={index === 0 ? "left" : "right"}>
            {/* <InputFields
              label="Field type"
              type="select"
              name={cols[index]?.name}
              options={options[index]}
              callBack={callBack}
            /> */}
            {item === '__EMPTY' ? "NA" : item}
          </StyledTableCell>
        ))
      }
    </>
  )
}

const ExcelReader = props => {
  const { label, styles } = props;
  const [error, setError] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [cols, setCols] = React.useState([]);
  const [showData, setShowData] = React.useState(true);
  const [uploading, setUploading] = React.useState(false);

  let defaultValues = {};

  cols.map(item => {
    defaultValues[item.name] = "";
    return item;
  });

  const { setValue, watch } = useFormContext();

  const reset = () => {
    setValue(`rows`, []);
  };

  const rowData = watch(`rows`) || [];

  const handleChange = e => {
    const files = e.target.files;

    if (files && files[0]) {
      const file = files[0];

      if (!allowedTypes.includes(file.type)) {
        setError("Invalid File Type");
        return false;
      } else setError("");

      setOpen(true);

      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = e => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true, cellDates: true, });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        setOpen(false);
        setCols(make_cols(ws['!ref']));
        setValue(`rows`, data);
      };

      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      };
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} style={styles ? styles : {}}>
        <TextField
          label={label}
          variant="outlined"
          type="file"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {
          rowData?.length ? (
            <IconButton style={{ marginTop: 8 }} onClick={reset}>
              <CancelIcon />
            </IconButton>
          ) : ""
        }
        {
          open ? <CircularProgress size={20} /> : ""
        }
      </Grid>
      <Grid item xs={6}>
        {
          watch('rows')?.length ? (
            <>
              {
                uploading ? (
                  <CircularProgress size={20} />
                ) : (
                  <NavButtonGroup
                    buttons={[0, 1]}
                    nextText="Upload Leads"
                    fullWidth
                    callBack={async () => {
                      setUploading(true);
                      const uData = await request({
                        url: '/bulk-uploads',
                        method: 'POST',
                        body: {
                          rows: watch('rows'),
                        }
                      });
                      setValue(`rows`, uData?.rows);
                      setUploading(false);
                    }}
                  />
                )
              }
            </>
          ) : ''
        }
      </Grid>
      <Grid item xs={12}>
        <span style={{ color: 'red', fontSize: 12 }}>
          {
            error ? <>{error}</> : ""
          }
        </span>
      </Grid>
      <Grid item xs={12} style={{ marginTop: -20 }}>
        {
          rowData?.length ? (
            <>
              {
                rowData?.[0]?.response ? (
                  <>
                    <b>Uploaded: </b><span>{rowData?.filter(item => item.response === 'Lead generated successfully.')?.length}</span><br />
                    <b>Rejected: </b><span>{rowData?.length - rowData?.filter(item => item.response === 'Lead generated successfully.')?.length}</span><br />
                  </>
                ) : ""
              }
              {showData ? <Button onClick={() => setShowData(false)}>Hide Data</Button> : <Button onClick={() => setShowData(true)}>Show Data</Button>}
              {
                showData ? (
                  <TableContainer component={Paper} style={{ height: 400 }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableHeader
                            //excelItems={excelItems}
                            cols={cols}
                            rowData={rowData}
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowData.map((item, index) => (
                          <StyledTableRow key={index} style={{
                            backgroundColor: "response" in item ? item.response === 'Lead generated successfully.' ? 'green' : 'red' : 'white'
                          }}>
                            {
                              Object.keys(rowData[index]).filter(item => item !== '_id').map((key, indx) => <StyledTableCell align={indx === 0 ? "left" : "right"}><span>{
                                Array.isArray(item[key]) ? item[key]?.join(' - ') : typeof item[key] === 'object' ? moment(item[key]).format('L') : item[key]}</span></StyledTableCell>)
                            }
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer >
                ) : ""
              }
            </>
          ) : ""
        }
      </Grid>
    </Grid>
  )

}

export default ExcelReader;