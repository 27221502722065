import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import { request } from '../utils/API';

const initialState = {
  blog: {},
  blogs: [],
  errorList: [],
  loading: false
}

export const getBlogs = createAsyncThunk('blogs/get', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/blogs',
      method: 'get'
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch blogs data. Please try again later!'])
  }
});

export const getBlog = createAsyncThunk('blog/get', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: `/blogs/${args}`,
      method: 'get'
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch blogs data. Please try again later!'])
  }
});

export const createBlog = createAsyncThunk('blog/create', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: `/blogs`,
      method: 'post',
      body: args
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch blogs data. Please try again later!'])
  }
});

export const updateBlog = createAsyncThunk('blog/update', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: `/blogs/${args._id}`,
      method: 'put',
      body: args
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch blogs data. Please try again later!'])
  }
});

export const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    setBlog: (state, action) => {
      state.blog = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Create Blog
    builder.addCase(createBlog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBlog.fulfilled, (state, action) => {
      state.blogs = [action.payload, ...state.blogs];
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(createBlog.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Get list of blogs
    builder.addCase(getBlogs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBlogs.fulfilled, (state, action) => {
      state.blogs = action.payload;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getBlogs.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Get single blog
    builder.addCase(getBlog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBlog.fulfilled, (state, action) => {
      state.blog = action.payload;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getBlog.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Update single blog
    builder.addCase(updateBlog.fulfilled, (state, action) => {
      state.blog = action.payload;
      state.errorList = [];
    });
    builder.addCase(updateBlog.rejected, (state, action) => {
      state.errorList = action.payload;
    });
  }
});

export const { setBlog } = blogsSlice.actions

export default blogsSlice.reducer