import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import { UserRoutes } from "../../routes";

const StyledDiv = styled("div")(({ theme }) => ({
  width: "100%",
  "& .left": {
    height: "101.9vh",
    background: `repeating-linear-gradient(
        135deg,
        #000,
        #000 600px,
        ${theme.palette.grey[300]} 300px,
        ${theme.palette.grey[300]} 900px
      )`,
    "& .logo": {
      padding: "30px 30px",
    },
  },
  "& .banner": {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10%",
    width: "50%",
    zIndex: 10,
  },
}));

const Users = (props) => {
  return (
    <StyledDiv>
      <Grid container spacing={2}>
        <Grid item xs={8} className="left">
          <div className="logo">
            <img
              src="https://klassfin.s3.ap-south-1.amazonaws.com/logo.png"
              width="80px"
            />
          </div>
          <img
            src="https://klassfin.s3.ap-south-1.amazonaws.com/klassfin.png"
            className="banner"
          />
        </Grid>
        <Grid item xs={4}>
          <UserRoutes />
        </Grid>
      </Grid>
    </StyledDiv>
  );
};

export default Users;
