import React from 'react';
import { styled } from '@mui/material/styles';

import {
  Login,
} from '../../Components';

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '200px 20px 0 20px',
  '& .middle': {
    marginTop: 20,
    fontSize: 25,
    color: theme.palette.primary.main,
    fontWeight: 'bolder'
  },
  '& .side': {
    borderBottom: `1px dotted ${theme.palette.primary.main}`,
    marginBottom: 18
  },
  '& .middle-wrapper': {
    width: '90%',
    margin: 'auto'
  },
  '& .bottom-nav': {
    textAlign: 'center',
    fontWeight: 'bolder',
    marginTop: 20,
  },
  '& a': {
    color: theme.palette.primary.main,
    marginLeft: 10,
    textDecoration: 'none',
    borderBottom: `1px dotted ${theme.palette.primary.main}`
  }
}));

const LoginPage = () => {
  return (
    <StyledDiv>
      <Login />
    </StyledDiv>
  )
}

export default LoginPage;