import React from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import { getColleges } from "../../store/colleges";

const StyledDiv = styled("div")(({ theme }) => ({
  width: "100%",
}));

const columns = [
  {
    field: "_id",
    headerName: "Actions",
    renderCell: (params) => (
      <NavLink to={`/colleges/${params.value}`}>
        <IconButton>
          <RemoveRedEyeOutlined color="primary" />
        </IconButton>
      </NavLink>
    ),
  },
  {
    field: "ranks",
    headerName: "Ranks",
    width: 180,
    renderCell: (params) => (
      <>
        CD: {params?.value?.cd}
        {` `}
        {params?.value?.the && (
          <>
            THE: {params?.value?.the}
            <br />
          </>
        )}
        {params?.value?.qs && <>QS: {params?.value?.qs}</>}
        {` `}
        {params?.value?.usNews && <>US NEWS: {params?.value?.usNews}</>}
      </>
    ),
  },
  { field: "name", headerName: "College Name", width: 250 },
  {
    field: "fees",
    headerName: "Fees (Avg.)",
    width: 130,
    renderCell: (params) => (
      <>
        {params?.value?.avgTuitionFee && (
          <>
            Tuition: ₹{params?.value?.avgTuitionFee} <br />
          </>
        )}
        {params?.value?.avgLivingFee && (
          <>Living: ₹{params?.value?.avgLivingFee}</>
        )}
      </>
    ),
  },
  {
    field: "address",
    headerName: "Country",
    width: 70,
    renderCell: (params) => params?.value?.country?.shortCode,
  },
  {
    field: "acceptanceRate",
    headerName: "Acceptance Rate",
    width: 130,
    renderCell: (params) => (params?.value ? `${params?.value}%` : ""),
  },
  {
    field: "topCourseData",
    headerName: "Top Course",
    width: 400,
    renderCell: (params) => (
      <>
        {params?.value?.topCourse}{" "}
        {params?.value?.topCourseFee && (
          <> - [₹{params?.value?.topCourseFee}]</>
        )}
        {params?.value?.topCourseDuration && (
          <>
            <br />
            {params?.value?.topCourseDuration}
          </>
        )}
      </>
    ),
  },
];

const Colleges = () => {
  const dispatch = useDispatch();
  const colleges = useSelector((state) => state.colleges.colleges);

  React.useEffect(() => {
    dispatch(getColleges());
  }, []);

  return (
    <StyledDiv>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <NavLink to="/colleges/create">
            <Button startIcon={<AddIcon />} variant="outlined">
              Create New College
            </Button>
          </NavLink> */}
        </Grid>
        <Grid item xs={12} style={{ height: "83vh", width: "100%" }}>
          <DataGrid
            rows={colleges.map((college) => ({
              ...college,
              id: college._id,
              fees: {
                avgTuitionFee: college?.avgTuitionFee
                  ? `${college?.avgTuitionFee} ${college.avgTuitionFeeUnit}/Yr`
                  : "",
                avgLivingFee: college?.avgLivingFee
                  ? `${college?.avgLivingFee} ${college.avgLivingFeeUnit}/Yr`
                  : "",
              },
              ranks: {
                cd: college?.cdRank,
                the: college?.rankByThe,
                qs: college?.rankByQS,
                usNews: college?.rankByUSNews,
              },
              topCourseData: {
                topCourse: college?.topCourse,
                topCourseFee: college?.topCourseFee
                  ? `${college?.topCourseFee} ${college?.topCourseFeeUnit}/Yr`
                  : "",
                topCourseDuration: college?.topCourseDuration
                  ? `${college?.topCourseDuration} ${college?.topCourseDurationUnit}`
                  : "",
              },
            }))}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50, 100, 500]}
            pagination
          />
        </Grid>
      </Grid>
    </StyledDiv>
  );
};

export default Colleges;
