import { API_ROOT } from './api-config';

const BASE_URL = API_ROOT;

export const request = (options, defaultHeaders = true) => {
  let headers;
  if (defaultHeaders && !options.headerType) {
    headers = new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
  } else if (options.headerType === 'file') {
    headers = new Headers();
  } else {
    headers = new Headers();
  }

  if (localStorage.getItem('accessToken') || localStorage.getItem('accessTokenE')) {
    headers.append('Authorization', `Bearer ${localStorage.getItem('accessToken') || localStorage.getItem('accessTokenE')}`);
  }

  options.headers = headers;
  if (options.headerType !== 'file') options.body = JSON.stringify(options.body);

  return new Promise((resolve, reject) => {
    fetch(`${BASE_URL}${options.url}`, options)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          if (response?.logout && localStorage.getItem('accessToken')) {
            localStorage.removeItem('accessToken');
            window.location.reload();
          }
          reject(response);
        }
        resolve(response);
      })
      .catch(error => console.log({ title: "Failed", message: "Something went wrong! Please try again later" }));
  });
};