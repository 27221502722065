import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import { request } from '../utils/API';

const initialState = {
  tag: {},
  tags: [],
  errorList: [],
  loading: false
}

export const getTags = createAsyncThunk('tags/get', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/tags',
      method: 'get'
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch tags data. Please try again later!'])
  }
});

export const getTag = createAsyncThunk('tag/get', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: `/tags/${args}`,
      method: 'get'
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch tags data. Please try again later!'])
  }
});

export const createTag = createAsyncThunk('tag/create', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: `/tags`,
      method: 'post',
      body: args
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch tags data. Please try again later!'])
  }
});

export const updateTag = createAsyncThunk('tag/update', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: `/tags/${args._id}`,
      method: 'put',
      body: args
    });

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch tags data. Please try again later!'])
  }
});

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTag: (state, action) => {
      state.tag = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Create Tag
    builder.addCase(createTag.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTag.fulfilled, (state, action) => {
      state.tags = [action.payload, ...state.tags];
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(createTag.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Get list of tags
    builder.addCase(getTags.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.tags = action.payload;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getTags.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Get single tag
    builder.addCase(getTag.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTag.fulfilled, (state, action) => {
      state.tag = action.payload;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getTag.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Update single tag
    builder.addCase(updateTag.fulfilled, (state, action) => {
      state.tag = action.payload;
      state.errorList = [];
    });
    builder.addCase(updateTag.rejected, (state, action) => {
      state.errorList = action.payload;
    });
  }
});

export const { setTag } = tagsSlice.actions

export default tagsSlice.reducer