import React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router } from "react-router-dom";

import { Users, Dashboard } from "./layouts";
import { logout, getMe } from "./store/users";
import { SnackBar } from './Components';

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#57326c"
    },
    secondary: {
      main: "#EC7C1C"
    },
  }
});

function App() {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.users.currentUser);

  React.useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');

    if (!currentUser.isLogedIn && accessToken) dispatch(getMe());
    else if (!accessToken) dispatch(logout());
  }, [currentUser.isLogedIn]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <SnackBar />
        <CssBaseline />
        <Router>
          {
            currentUser?.isLogedIn === null ? <div>Loading...</div> : (
              <>
                {
                  !currentUser.isLogedIn ? <Users /> : <Dashboard />
                }
              </>
            )
          }
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
