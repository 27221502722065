import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "../utils/API";

const initialState = {
  count: 0,
  college: {},
  colleges: [],
  errorList: [],
  loading: false,
};

export const getColleges = createAsyncThunk(
  "colleges/get",
  async (args = null, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "/colleges",
        method: "get",
      });

      return response;
    } catch (err) {
      return rejectWithValue(
        err.details || ["Unable fetch colleges data. Please try again later!"]
      );
    }
  }
);

export const getCollege = createAsyncThunk(
  "college/get",
  async (args = null, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/colleges/${args}`,
        method: "get",
      });

      return response;
    } catch (err) {
      return rejectWithValue(
        err.details || ["Unable fetch colleges data. Please try again later!"]
      );
    }
  }
);

export const createCollege = createAsyncThunk(
  "college/create",
  async (args = null, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/colleges`,
        method: "post",
        body: args,
      });

      return response;
    } catch (err) {
      return rejectWithValue(
        err.details || ["Unable fetch colleges data. Please try again later!"]
      );
    }
  }
);

export const updateCollege = createAsyncThunk(
  "college/update",
  async (args = null, { rejectWithValue }) => {
    try {
      const response = await request({
        url: `/colleges/${args._id}`,
        method: "put",
        body: args,
      });

      return response;
    } catch (err) {
      return rejectWithValue(
        err.details || ["Unable fetch colleges data. Please try again later!"]
      );
    }
  }
);

export const collegesSlice = createSlice({
  name: "colleges",
  initialState,
  reducers: {
    setCollege: (state, action) => {
      state.college = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Create College
    builder.addCase(createCollege.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCollege.fulfilled, (state, action) => {
      state.colleges = [action.payload, ...state.colleges];
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(createCollege.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Get list of colleges
    builder.addCase(getColleges.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getColleges.fulfilled, (state, action) => {
      state.colleges = action.payload.data;
      state.count = action.payload.count;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getColleges.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Get single college
    builder.addCase(getCollege.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCollege.fulfilled, (state, action) => {
      state.college = action.payload;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getCollege.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });

    // Update single college
    builder.addCase(updateCollege.fulfilled, (state, action) => {
      state.college = action.payload;
      state.errorList = [];
    });
    builder.addCase(updateCollege.rejected, (state, action) => {
      state.errorList = action.payload;
    });
  },
});

export const { setCollege } = collegesSlice.actions;

export default collegesSlice.reducer;
