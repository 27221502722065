import React from "react";
import { styled } from "@mui/material/styles";

const StyledDiv = styled("div")(({ theme }) => ({
  width: "100%",
}));

const SettingsPage = () => {
  return <StyledDiv>Settings</StyledDiv>;
};

export default SettingsPage;
