import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import {
  useForm,
  FormProvider
} from "react-hook-form";

import {
  InputFields,
  NavButtonGroup,
  Backdrop,
} from '../../Components';
import loginSchema from '../../validations/login';
import { updateSnackbar } from '../../store/snackbar';
import { login } from '../../store/users';

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '0 20px',
  "& .top-title": {
    fontSize: 60,
    fontWeight: 'bolder',
    width: '100%',
    color: theme.palette.primary.main,
    textAlign: 'center'
  },
  "a": {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  "& .login": {
    marginTop: 17
  },
  "& .google-button": {
    padding: 18,
    marginTop: 10,
    marginLeft: "25%"
  },
  "& .or-section": {
    marginTop: 7,
    textAlign: 'center'
  },
  '& .error-message': {
    fontSize: 12,
    color: theme.palette.error.main
  }
}));

const LogIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const { errorList, loading } = useSelector(state => state.users);

  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
    mode: "onChange"
  });

  const { formState: { isValid, isDirty, errors }, handleSubmit } = methods;

  const onSubmit = async data => {
    await dispatch(login(data));
    navigate('/dashboard');
  }

  React.useEffect(() => {
    loading ? setOpen(true) : setOpen(false);
  }, [loading]);

  React.useEffect(() => {
    if (errorList?.length) {
      dispatch(updateSnackbar({
        status: true,
        message: errorList,
        type: 'error'
      }));
    }
  }, [errorList]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Backdrop open={open} />
        <StyledDiv>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='top-title'>
                LOGIN
              </div>
            </Grid>
            <Grid item xs={12}>
              <InputFields
                type="text"
                name={`email`}
                label="Email"
                placeholder="eg. john@xyz.com"
              />
              <span className='error-message'>{errors?.email?.message}</span>
            </Grid>
            <Grid item xs={12}>
              <InputFields
                type={showPassword ? 'text' : 'password'}
                name={`password`}
                label="Your Password"
                placeholder="*******"
                endAdornment={
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                }
              />
              <span className='error-message'>{errors?.password?.message}</span>
            </Grid>
            <Grid item xs={6}>
              <NavButtonGroup
                buttons={[0, 1]}
                nextText="Login"
                nextDisabled={!(isValid && isDirty)}
                fullWidth
              />
            </Grid>
          </Grid>
        </StyledDiv>
      </form>
    </FormProvider>
  )
}

export default LogIn;