import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import { request } from '../utils/API';

const initialState = {
  currentUser: {
    isLogedIn: null
  },
  users: [],
  external: {},
  roles: [],
  errorList: [],
  loading: false
}

export const login = createAsyncThunk('users/login', async (body, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/users/login',
      method: 'post',
      body
    });

    localStorage.setItem('accessToken', response.token);
    response.user.isLogedIn = true;

    return response;
  } catch (err) {
    return rejectWithValue(err.details || ['Unable to login. Please try again later!'])
  }
});

export const getMe = createAsyncThunk('users/getMe', async (args = null, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/users/me',
      method: 'get'
    });

    return {
      ...response,
      isLogedIn: true
    };
  } catch (err) {
    return rejectWithValue(err.details || ['Unable fetch user data. Please try again later!'])
  }
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    logout: (state, action) => {
      localStorage.removeItem('accessToken');
      state.currentUser = { isLogedIn: false };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.currentUser = action.payload.user;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.errorList = action.payload;
      state.loading = false;
    });
    builder.addCase(getMe.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.errorList = [];
      state.loading = false;
    });
    builder.addCase(getMe.rejected, (state, action) => {
      console.log(action.payload)
      state.errorList = action.payload;
      state.loading = false;
    });
  }
})

export const { logout } = usersSlice.actions

export default usersSlice.reducer