import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  InputFields,
  NavButtonGroup,
  Backdrop
} from '../../Components';

import { updateSnackbar } from '../../store/snackbar';
import { createLead } from '../../store/leads';

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
}));

const CreateLead = () => {
  const dispatch = useDispatch();
  const { loading, errorList } = useSelector(state => state.leads);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      fullName: '',
      phone: '',
      email: '',
      targetCountry: '',
      collegeName: '',
      courseName: '',
      courseFee: '',
      loanAmount: '',
      bankName: '',
      comments: '',
    }
  });

  const { handleSubmit } = methods;

  const onSubmit = async data => {
    const lead = await dispatch(createLead(data));
    navigate(`/leads/${lead.payload._id}`);
  }

  React.useEffect(() => {
    loading ? setOpen(true) : setOpen(false);
  }, [loading]);

  React.useEffect(() => {
    if (errorList?.length) {
      dispatch(updateSnackbar({
        status: true,
        message: errorList,
        type: 'error'
      }));
    }
  }, [errorList]);

  return (
    <StyledDiv>
      <Backdrop open={open} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Create a new Lead
              </Typography>
            </Grid>
            <Grid item xs={4} className='detail-box'>
              <InputFields
                name="fullName"
                label="Full Name"
                type="text"
                size="small"
                placeholder="eg. John Doe"
              />
            </Grid>
            <Grid item xs={4} className='detail-box details-middle'>
              <InputFields
                name="phone"
                label="Phone"
                type="number"
                size="small"
                placeholder="eg. 1234567890"
              />
            </Grid>
            <Grid item xs={4} className='detail-box details-right'>
              <InputFields
                name="email"
                label="Email"
                type="text"
                size="small"
                placeholder="eg. john@gmail.com"
              />
            </Grid>
            <Grid item xs={4} className='detail-box'>
              <InputFields
                name="targetCountry"
                label="Target Country"
                type="text"
                size="small"
                placeholder="eg. Australia"
              />
            </Grid>
            <Grid item xs={4} className='detail-box'>
              <InputFields
                name="collegeName"
                label="College Name"
                type="text"
                size="small"
              />
            </Grid>
            <Grid item xs={4} className='detail-box details-middle'>
              <InputFields
                name="courseName"
                label="Course Name"
                type="text"
                size="small"
              />
            </Grid>
            <Grid item xs={4} className='detail-box details-right'>
              <InputFields
                name="courseFee"
                label="Course Fee"
                type="number"
                size="small"
              />
            </Grid>
            <Grid item xs={4} className='detail-box'>
              <InputFields
                name="loanAmount"
                label="Loan Amount"
                type="number"
                size="small"
              />
            </Grid>
            <Grid item xs={4} className='detail-box details-middle'>
              <InputFields
                name="bankName"
                label="Bank Name"
                type="text"
                size="small"
              />
            </Grid>
            <Grid item xs={12} className='detail-box'>
              <InputFields
                name="comments"
                label="Comments"
                type="text"
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <NavButtonGroup
                buttons={[0, 1]}
                nextText="Create Lead"
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </StyledDiv>
  )
}

export default CreateLead;