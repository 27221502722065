import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Login } from '../Blocks';

const UserRoutes = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);

export default UserRoutes;