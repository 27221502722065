import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import {
  Dashboard,
  Leads,
  CreateLead,
  ViewLead,
  BulkUploadLead,
  Blogs,
  ViewBlog,
  CreateBlog,
  Colleges,
  ViewCollege,
  CreateCollege,
  Settings,
} from "../Blocks";

const MainRoutes = () => (
  <Routes>
    {/* Dashboard */}
    <Route path="/dashboard" element={<Dashboard />} />

    {/* Leads */}
    <Route path="/leads" element={<Leads />} />
    <Route path="/leads/create" element={<CreateLead />} />
    <Route path="/leads/:_id" element={<ViewLead />} />
    <Route path="/leads/bulk-upload" element={<BulkUploadLead />} />

    {/* Blogs */}
    <Route path="/blogs" element={<Blogs />} />
    <Route path="/blogs/:_id" element={<ViewBlog />} />
    <Route path="/blogs/create" element={<CreateBlog />} />

    {/* Colleges */}
    <Route path="/colleges" element={<Colleges />} />
    <Route path="/colleges/:_id" element={<ViewCollege />} />
    <Route path="/colleges/create" element={<CreateCollege />} />

    {/* Settings */}
    <Route path="/settings" element={<Settings />} />
    <Route path="*" element={<Navigate to="/dashboard" replace />} />
  </Routes>
);

export default MainRoutes;
