import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTheme } from '@mui/material/styles'

import useUpload from "../../Hooks/useUpload";

let backendHost = process.env.REACT_APP_REMOTE_URL_LOCAL;

if (process.env.REACT_APP_MODE === 'production') backendHost = process.env.REACT_APP_REMOTE_URL_PRODUCTION;
else if (process.env.REACT_APP_MODE === 'staging') backendHost = process.env.REACT_APP_REMOTE_URL_STAGING;

const url = `${backendHost}/media`;

const Uploader = ({ reftype, refId, type, uploadCallBack, accept, boxText, multiple }) => {
  const theme = useTheme();
  const baseStyle = {
    alignItems: "center",
    textAlign: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.grey[300],
    borderStyle: "dashed",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    outline: "none",
    transition: "border .24s ease-in-out",
    height: 140,
    paddingTop: 26,
    cursor: 'pointer'
  };

  const activeStyle = {
    borderColor: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    height: 140,
    paddingTop: 26,
  };

  const acceptStyle = {
    borderColor: theme.palette.success.main,
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
    height: 140,
    paddingTop: 26,
  };

  const rejectStyle = {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    height: 140,
    paddingTop: 26,
  };

  const { uploadFile, files, currentStatus } = useUpload();

  const onDrop = async acceptedFiles => {
    uploadFile(acceptedFiles, reftype, refId, type);
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    maxSize: 15000000,
    multiple: multiple !== true ? multiple : true,
    accept: accept
  });

  const style = useMemo(
    () => ({
      ...(baseStyle),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  React.useEffect(() => {
    uploadCallBack && files.length && uploadCallBack(files?.filter(file => file._id));
  }, [JSON.stringify(files)]);

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div>
            {isDragAccept ? 'Drop the file here to upload' : 'File type not accepted, sorry!'}
          </div>
        ) : (
          <div>
            {boxText || `Upload Documents`} <br /><br /><UploadFileIcon color="primary" />
            {currentStatus === "uploading" && (
              <CircularProgress
                color="primary"
                disableShrink
                size={20}
              />
            )}
            {currentStatus === "completed" && (
              <DoneIcon color="success" />
            )}
          </div>
        )}
      </div>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {
          files && files.length > 0 && (
            <>
              {
                files.map((file, index) => (
                  <li key={index} style={{ padding: 10, background: theme.palette.grey[300], marginTop: 5 }}>
                    {file.path || file.name}
                    <small style={{ float: 'right' }}>
                      {
                        file.uploadStatus === 'uploading' ? <span style={{ color: theme.palette.warning.main }}>Uploading...</span> : file.uploadStatus === 'uploaded' ? <span style={{ color: theme.palette.success.main }}>Successfully Uploaded</span> : <span style={{ color: theme.palette.error.main }}>Upload Failed</span>
                      }
                    </small>
                  </li>
                ))
              }
            </>
          )
        }
      </ul>
    </>
  )
}

export default React.memo(Uploader);