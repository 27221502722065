import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';

import {
  NavButtonGroup,
  Backdrop,
  UploadBox,
  ExcelReader
} from '../../Components';

import { updateSnackbar } from '../../store/snackbar';
import { createLead } from '../../store/leads';
import { request } from '../../utils/API';

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
}));

const columns = [
  { field: 'fileName', headerName: 'File name', width: 400, renderCell: params => params?.value?.name },
  { field: 'createdAt', headerName: 'Import Start Datetime', width: 200, renderCell: params => params?.value ? moment(params?.value).format('ll') : 'NA' },
  { field: 'status', headerName: 'Import Status', width: 200, renderCell: params => params?.value ? 'Complete' : 'Pending...' },
  {
    field: '_id', headerName: 'Actions', renderCell: params => (
      <NavLink
        to={`/leads/bulk-upload/${params.value}`}
        style={{ marginLeft: 10 }}
      >
        <IconButton>
          <RefreshIcon color="primary" />
        </IconButton>
      </NavLink>
    )
  }
];

const BulkUploadLead = () => {
  const dispatch = useDispatch();
  const { loading, errorList } = useSelector(state => state.leads);
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  const methods = useForm({
    defaultValues: {
      rows: []
    }
  });

  React.useEffect(() => {
    loading ? setOpen(true) : setOpen(false);
  }, [loading]);

  React.useEffect(() => {
    if (errorList?.length) {
      dispatch(updateSnackbar({
        status: true,
        message: errorList,
        type: 'error'
      }));
    }
  }, [errorList]);
  
  return (
    <StyledDiv>
      <Backdrop open={open} />
      <FormProvider {...methods}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Create a new Lead
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* <UploadBox
                boxText='Click or Drag and drop XL file here to import leads'
                reftype='bulk-sheet'
                refId={null}
                multiple={false}
                uploadCallBack={async uploadedFiles => {
                  const bulkUploadData = await request({
                    url: '/bulk-uploads',
                    method: 'POST',
                    data: {
                      file: uploadedFiles?.[0]?._id
                    }
                  });
                  setRows([bulkUploadData, ...rows]);
                }}
                accept={{
                  'application/vnd.ms-excel': ['.xls', '.xlsx'],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                }}
              /> */}
              <ExcelReader label={`UPLOAD DATA`} />
            </Grid>
            {/* <Grid item xs={12}>
              <b>Import History</b>
            </Grid>
            <Grid item xs={12} style={{ height: '83vh', width: '100%' }}>
              <DataGrid
                rows={rows.map(item => ({ ...item, id: item.id }))}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                checkboxSelection
              />
            </Grid> */}
          </Grid>
        </form>
      </FormProvider>
    </StyledDiv>
  )
}

export default BulkUploadLead;