import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import { getBlogs } from '../../store/blogs';

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
}));

const columns = [
  { field: 'title', headerName: 'Blog Title', width: 600 },
  { field: 'createdAt', headerName: 'Creation Date', width: 200, renderCell: params => params?.value ? moment(params?.value).format('lll') : '' },
  { field: 'status', headerName: 'Status', width: 110, renderCell: params => params?.value === 2 ? 'Published' : 'Draft' },
  {
    field: '_id', headerName: 'Actions', renderCell: params => (
      <Stack direction="row">
        <NavLink
          to={`/blogs/${params.value}`}
        >
          <IconButton>
            <RemoveRedEyeOutlined color="primary" />
          </IconButton>
        </NavLink>
        <IconButton>
          <DeleteIcon color="error" />
        </IconButton>
      </Stack>
    )
  }
];

const Blogs = () => {
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blogs.blogs);

  React.useEffect(() => {
    dispatch(getBlogs());
  }, []);

  return (
    <StyledDiv>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NavLink
            to="/blogs/create"
          >
            <Button startIcon={<AddIcon />} variant="outlined">
              Create New Blog
            </Button>
          </NavLink>
        </Grid>
        <Grid item xs={12} style={{ height: '83vh', width: '100%' }}>
          <DataGrid
            rows={blogs.map(blog => ({ ...blog, id: blog._id }))}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
          />
        </Grid>
      </Grid>
    </StyledDiv >
  )
}

export default Blogs;