import React from 'react';
import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
}));

const Dashboard = () => {
  localStorage.removeItem('EMAIL');

  return (
    <StyledDiv>
      Dashboard
    </StyledDiv>
  )
}

export default Dashboard;